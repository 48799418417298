import {
	addPatientInfoObservations,
	addPrimaryPatientConditions,
	removePatientInfoObservations,
	removePrimaryPatientConditions,
	setGenderIdentity,
	setPatientAge,
	setPatientCareTeamParticipants,
	setPatientName,
	setPatientPreferredLanguage,
} from 'api/patients.js';
import { getHealthSystemVerbalRedirectionLanguages } from 'api/verbalRedirections.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import Button from 'components/Button.jsx';
import FormInput from 'components/FormInput.jsx';
import { Alert, CustomDropdown, Dropdown, Form, Grid, Loader } from 'components/index.js';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import { CareTeam, ConditionType, ObservationType } from 'constants/enums.js';
import {
	CareTeamParticipantRole,
	genderIdentityArr,
	primaryAdverseEvent,
	primaryPatientCondition,
	primaryPatientRiskFactor,
} from 'constants/monitoring.js';
import translate from 'i18n-translations/translate.jsx';
import CalendarIcon from 'icons/Dashboard/CalendarIcon.jsx';
import { DischargeIcon, EditIcon, ProfileIcon } from 'icons/Monitoring/index.js';
import { getAge, getConfigurationValue, setSimplifiedLanguageLabels } from 'infrastructure/helpers/commonHelpers.js';
import { customFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import { mapSelectedOptions } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';

const PatientNotes = ({
	deviceId,
	roomId,
	hierarchyNaming,
	isDarkMode,
	mrn,
	patientName,
	patientAge,
	birthDate,
	genderIdentity,
	handleDiscontinue,
	ehrCareTeam,
	healthSystemId,
	deviceOwnerId,
	conferenceId,
	shouldShowDiscontinueReasons,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const maxCharacterLength = 256;
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [preferredLanguages, setPreferredLanguages] = useState([]);
	const patientDataSaved = useSelector(state => state.patientNotes.savedMode);
	const healthSystemConfigurations = useSelector(state => state.configurations.healthSystemConfigurations);
	const [patientNotes, setPatientNotes] = useState({
		patientName: '',
		patientAge: null,
		birthDate: '',
		nurseNameNumber: '',
		preferredLanguage: null,
		genderIdentity: null,
		nurseAssistantNameNumber: '',
		tlNameNumber: '',
		primaryAdverseEvent: [],
		primaryPatientCondition: [],
		primaryPatientRiskFactor: [],
		titleBarNotes: '',
		additionalNotes: '',
	});
	const [expandedBox, setExpandedBox] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);

	const initialPatientNotes = useRef(null);
	const prevDeviceIdRef = useRef();
	const prevRoomIdRef = useRef();

	const patientNotesProperties = [
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientName]) && 'patientName',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && 'birthDate',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && 'patientAge',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientGenderIdentity]) && 'genderIdentity',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseNameNumber]) && 'nurseNameNumber',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseAssistantNameNumber]) && 'nurseAssistantNameNumber',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TlNameNumber]) && 'tlNameNumber',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryAdverseEvent]) && 'primaryAdverseEvent',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientCondition]) && 'primaryPatientCondition',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientRiskFactor]) && 'primaryPatientRiskFactor',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PreferredLanguage]) && 'preferredLanguage',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TitleBarNotes]) && 'titleBarNotes',
		getConfigurationValue(healthSystemConfigurations[MonitoringSettings.AdditionalPatientNotes]) && 'additionalNotes',
	].filter(Boolean);

	useEffect(() => {
		const fetchSelectedLanguages = async () => {
			if (!healthSystemId) {
				return;
			}
			const response = await getHealthSystemVerbalRedirectionLanguages(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = setSimplifiedLanguageLabels(response.languages);
			setPreferredLanguages(mapped);
		};
		fetchSelectedLanguages();
	}, [intl, healthSystemId]);

	useEffect(() => {
		if (roomId !== prevRoomIdRef.current && showEditForm) {
			handleSubmit(true);
		}
		if (!patientDataSaved?.[deviceId]?.hasFetchedNotes) {
			return;
		}
		setPatientNotes(prevState => {
			const {
				patientName,
				birthDate,
				patientAge,
				genderIdentity,
				nurseNameNumber,
				nurseAssistantNameNumber,
				tlNameNumber,
				primaryAdverseEvent,
				primaryPatientCondition,
				primaryPatientRiskFactor,
				preferredLanguage,
				titleBarNotes,
				additionalNotes,
			} = patientDataSaved[deviceId];
			return {
				...prevState,
				patientName: patientName || prevState.patientName,
				birthDate: birthDate || prevState.birthDate,
				patientAge: patientAge || prevState.patientAge,
				genderIdentity: genderIdentity || prevState.genderIdentity,
				nurseNameNumber: nurseNameNumber || prevState.nurseNameNumber,
				nurseAssistantNameNumber: nurseAssistantNameNumber || prevState.nurseAssistantNameNumber,
				tlNameNumber: tlNameNumber || prevState.tlNameNumber,
				primaryAdverseEvent: primaryAdverseEvent || prevState.primaryAdverseEvent,
				primaryPatientCondition: primaryPatientCondition || prevState.primaryPatientCondition,
				primaryPatientRiskFactor: primaryPatientRiskFactor || prevState.primaryPatientRiskFactor,
				preferredLanguage: getPreferredLanguage(preferredLanguage) || prevState.preferredLanguage,
				titleBarNotes: titleBarNotes || prevState.titleBarNotes,
				additionalNotes: additionalNotes || prevState.additionalNotes,
			};
		});
		prevDeviceIdRef.current = deviceId;
		prevRoomIdRef.current = roomId;
	}, [deviceId, roomId, preferredLanguages, patientDataSaved?.[deviceId]?.hasFetchedNotes]);

	const getUpdatedFields = () => {
		const updatedFields = {};
		Object.keys(initialPatientNotes.current).forEach(key => {
			if (['string', 'number'].includes(typeof patientNotes[key]) && patientNotes[key] !== initialPatientNotes.current[key]) {
				updatedFields[key] = patientNotes[key] || '';
			} else if (Array.isArray(patientNotes[key])) {
				const removed = initialPatientNotes.current[key].filter(item => !patientNotes[key].includes(item));
				const added = patientNotes[key].filter(item => !initialPatientNotes.current[key].includes(item));
				updatedFields[key] = { removed, added };
			} else if (['preferredLanguage'].includes(key) && initialPatientNotes.current[key]?.id !== patientNotes[key]?.id) {
				updatedFields[key] = patientNotes[key];
			}
		});
		return updatedFields;
	};

	const handleUpdatedObservations = updatedFields => {
		const observationsToAdd = [];
		const observationsToRemove = [];
		const observationTypes = {
			titleBarNotes: ObservationType.ADDITIONAL_TITLE_BAR_NOTES,
			additionalNotes: ObservationType.ADDITIONAL_PATIENT_NOTES,
			primaryAdverseEvent: ObservationType.PRIMARY_ADVERSE_EVENT,
			primaryPatientRiskFactor: ObservationType.PRIMARY_PATIENT_RISK_FACTOR,
		};

		Object.keys(observationTypes).forEach(field => {
			if (!(field in updatedFields)) {
				return;
			}
			if (typeof updatedFields[field] === 'object') {
				observationsToRemove.push(
					...updatedFields[field].removed.map(item => ({
						...item,
						observationTypeId: observationTypes[field],
					}))
				);
				observationsToAdd.push(
					...updatedFields[field].added.map(item => ({
						...item,
						observationTypeId: observationTypes[field],
					}))
				);
			} else {
				(updatedFields[field] ? observationsToAdd : observationsToRemove).push({
					code: field,
					display: updatedFields[field] || null,
					observationTypeId: observationTypes[field],
				});
			}
		});

		return { observationsToAdd, observationsToRemove };
	};

	const handleSubmit = async savePrevious => {
		const ownerId = deviceOwnerId;
		const updatedFields = getUpdatedFields();
		const deviceIdToSave = savePrevious ? prevDeviceIdRef.current : deviceId;
		setIsLoading(true);

		if ('patientName' in updatedFields) {
			const firstName = updatedFields.patientName?.split(' ')?.[0] || null;
			const lastName = updatedFields.patientName?.split(' ')?.slice(1).join(' ') || null;
			await setPatientName({ healthSystemId, conferenceId, ownerId, firstName, lastName });
		}
		if ('birthDate' in updatedFields) {
			await setPatientAge({ healthSystemId, conferenceId, ownerId, dateOfBirth: updatedFields.birthDate || null });
		}
		if ('genderIdentity' in updatedFields) {
			await setGenderIdentity({ healthSystemId, conferenceId, ownerId, genderIdentity: updatedFields.genderIdentity || null });
		}
		if (updatedFields?.preferredLanguage) {
			const language = updatedFields.preferredLanguage.locale.split('-')[0];
			await setPatientPreferredLanguage({ conferenceId, ownerId, language });
		}
		const careTeamFields = ['nurseNameNumber', 'nurseAssistantNameNumber', 'tlNameNumber'];
		const careTeamRoles = {
			nurseNameNumber: CareTeamParticipantRole.REGISTERED_NURSE,
			nurseAssistantNameNumber: CareTeamParticipantRole.PATIENT_CARE_TECHNICIAN,
			tlNameNumber: CareTeamParticipantRole.NURSING_TEAM_LEADER,
		};
		if (careTeamFields.some(field => field in updatedFields)) {
			const participants = [];
			careTeamFields.forEach(field => {
				if (patientNotes[field]) {
					participants.push({ name: patientNotes[field], participantRole: careTeamRoles[field] });
				}
			});
			await setPatientCareTeamParticipants({ healthSystemId, conferenceId, ownerId, participants });
		}
		const { observationsToAdd, observationsToRemove } = handleUpdatedObservations(updatedFields);
		if (observationsToRemove.length > 0) {
			const observations = observationsToRemove.map(({ code, display, observationTypeId }) => ({
				code,
				display,
				observationTypeId,
			}));
			await removePatientInfoObservations({ healthSystemId, conferenceId, ownerId, deviceId: deviceIdToSave, observations });
		}
		if (observationsToAdd.length > 0) {
			const observations = observationsToAdd.map(({ code, display, observationTypeId }) => ({
				code,
				display,
				observationTypeId,
			}));
			await addPatientInfoObservations({ healthSystemId, conferenceId, ownerId, observations });
		}
		if (updatedFields.primaryPatientCondition?.removed.length > 0) {
			const conditions = updatedFields.primaryPatientCondition.removed.map(({ code, display }) => ({
				code,
				display,
				conditionType: ConditionType.PRIMARY_PATIENT_CONDITION,
			}));
			await removePrimaryPatientConditions({ conferenceId, ownerId, conditions });
		}
		if (updatedFields.primaryPatientCondition?.added.length > 0) {
			const conditions = updatedFields.primaryPatientCondition.added.map(({ code, display }) => ({
				code,
				display,
				conditionType: ConditionType.PRIMARY_PATIENT_CONDITION,
			}));
			await addPrimaryPatientConditions({ conferenceId, ownerId, conditions });
		}
		initialPatientNotes.current = { ...patientNotes };
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{ ...patientDataSaved[deviceIdToSave], ...patientNotes, notes: patientDataSaved[deviceIdToSave]?.notes || '' },
				deviceIdToSave
			)
		);
		setIsLoading(false);
		setShowEditForm(false);
	};

	const hasPatientInfo = () => {
		let result = false;
		for (let i = 0; i < patientNotesProperties.length; i++) {
			const item = patientNotes[patientNotesProperties?.[i]];
			if (item && Array.isArray(item) && item.length > 0) {
				result = true;
				break;
			}
			if (item && !Array.isArray(item)) {
				result = true;
				if (mrn && ['patientName', 'patientAge', 'genderIdentity'].includes(patientNotesProperties?.[i])) {
					result = false;
				}
				break;
			}
		}
		if (mrn && (patientName || patientAge || genderIdentity)) {
			return true;
		}

		return result;
	};

	const getTableData = () => {
		const tableRows = [];
		const languageItem = 'preferredLanguage';

		if (mrn) {
			tableRows.push(
				<>
					<tr key='patientMrn'>
						<td>MRN</td>
						<td>{mrn}</td>
					</tr>
					{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientName]) && patientName && (
						<tr key='patientName'>
							<td>{intl.formatMessage({ id: 'patientName' })}</td>
							<td>{patientName}</td>
						</tr>
					)}
					{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && patientAge && (
						<>
							<tr key='birthDate'>
								<td>DOB</td>
								<td>{customFormattedDate(birthDate, 'MM/DD/YYYY')}</td>
							</tr>
							<tr key='patientAge'>
								<td>{intl.formatMessage({ id: 'patientAge' })}</td>
								<td>{patientAge}</td>
							</tr>
						</>
					)}
					{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientGenderIdentity]) && genderIdentity && (
						<tr key='genderIdentity'>
							<td>{intl.formatMessage({ id: 'genderIdentity' })}</td>
							<td>{genderIdentityArr.find(item => item.value === genderIdentity)?.label || ''}</td>
						</tr>
					)}
					{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseNameNumber]) && ehrCareTeam?.nurse && (
						<tr key='nurseNameNumber'>
							<td>{intl.formatMessage({ id: 'nurseNameNumber' })}</td>
							<td>
								{ehrCareTeam?.nurse || ''}
								<br />
								{ehrCareTeam?.nursePhone?.maskedValue || ''}
							</td>
						</tr>
					)}
					{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseAssistantNameNumber]) && ehrCareTeam?.pca && (
						<tr key='nurseAssistantNameNumber'>
							<td>{intl.formatMessage({ id: 'nurseAssistantNameNumber' })}</td>
							<td>
								{ehrCareTeam?.pca || ''}
								<br />
								{ehrCareTeam?.pcaPhone?.maskedValue || ''}
							</td>
						</tr>
					)}
				</>
			);
		}
		for (let i = 0; i < patientNotesProperties.length; i++) {
			const patientNote = patientNotesProperties[i];
			const noteItem = patientNotes[patientNote];

			if (noteItem && Array.isArray(noteItem) && noteItem.length > 0) {
				const row = (
					<tr key={patientNote}>
						<td>{intl.formatMessage({ id: patientNote })}</td>
						<td>{mapSelectedOptions(noteItem)}</td>
					</tr>
				);
				tableRows.push(row);
			}
			if (!Array.isArray(noteItem) && noteItem?.toString()) {
				let row;
				const arr = ['patientName', 'patientAge', 'birthDate', 'nurseNameNumber', 'nurseAssistantNameNumber'];
				if (!arr.includes(patientNote) || (!mrn && arr.includes(patientNote))) {
					row = (
						<tr key={patientNote}>
							<td>{patientNote === 'birthDate' ? 'DOB' : intl.formatMessage({ id: patientNote })}</td>
							<td {...(['titleBarNotes', 'additionalNotes'].includes(patientNote) && { className: 'break-word' })}>
								{patientNote === 'birthDate' ? customFormattedDate(noteItem, 'MM/DD/YYYY') : noteItem}
							</td>
						</tr>
					);
				}
				if (patientNote === languageItem) {
					row = (
						<tr key={patientNote}>
							<td>{intl.formatMessage({ id: patientNote })}</td>
							<td>{noteItem.label}</td>
						</tr>
					);
				}

				if (patientNote === 'genderIdentity') {
					row = (
						<tr key={patientNote}>
							<td>{intl.formatMessage({ id: patientNote })}</td>
							<td>{genderIdentityArr.find(item => item.value === noteItem)?.label || ''}</td>
						</tr>
					);
				}

				tableRows.push(row);
			}
		}
		return tableRows;
	};

	const PatientInfo = () => {
		if (!hasPatientInfo()) {
			return (
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td>{translate('roomWithoutPatientData')}</td>
						</tr>
					</tbody>
				</table>
			);
		}

		return (
			<>
				<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>{getTableData()}</tbody>
				</table>
			</>
		);
	};

	const getInputValue = (nameKey, numberKey, notesData) => {
		if (mrn && ehrCareTeam) {
			const name = ehrCareTeam[nameKey] || '';
			const number = ehrCareTeam[numberKey]?.maskedValue || '';
			return `${name}${name && number && '/'}${number}`;
		}
		if (notesData) {
			return notesData;
		}
		return '';
	};

	const getPreferredLanguage = languageLocale => {
		if (!preferredLanguages.length || !languageLocale) {
			return null;
		}
		const locale = typeof languageLocale === 'object' ? languageLocale.locale : languageLocale;
		return preferredLanguages.find(
			language =>
				language.locale.split('-')?.[0] === locale?.split('-')?.[0] ||
				language.localeName.split(' ')?.[0] === locale?.split(' ')?.[0]
		);
	};

	const handleDateChange = selectedDate => {
		setPatientNotes(prevState => ({
			...prevState,
			birthDate: selectedDate?.toISOString() || '',
			patientAge: selectedDate ? getAge(selectedDate) ?? '' : '',
		}));
	};

	const getGenderIdentityDefaultOption = () => {
		if (mrn) {
			return genderIdentity ? genderIdentityArr.find(item => item.value === genderIdentity) : [];
		}
		if (patientNotes?.genderIdentity) {
			return patientNotes?.genderIdentity ? genderIdentityArr.find(item => item.value === patientNotes?.genderIdentity) : [];
		}
		return [];
	};

	return (
		<>
			<div className='monitoring-timeline-box'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title'>
						<ProfileIcon />
						{translate('patientInfo')}
					</p>
					<div className='timeline-box-actions'>
						{patientNotesProperties?.length > 0 && (
							<Button
								onClick={() => {
									setExpandedBox(true);
									setShowEditForm(prevState => !prevState);
									initialPatientNotes.current = { ...patientNotes };
								}}
								svgIcon={<EditIcon />}
								border='none'
							/>
						)}
						<Button
							border='none'
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='patient-informations'>
							{isLoading && (
								<Grid columns='1fr' rows='1fr' horizAlign='center' vertAlign='center'>
									<Loader />
								</Grid>
							)}
							{!isLoading && (
								<>
									{!showEditForm && <PatientInfo />}
									{showEditForm && patientNotesProperties?.length > 0 && (
										<Form className='profile-information-form patient-notes-form'>
											<div className='flex flex-space-between flex-wrap'>
												<div className='room-level'>
													<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>

													<Dropdown position='bottom' icon='more_vert'>
														<Button
															className='button discharge-btn'
															onClick={() => handleDiscontinue(shouldShowDiscontinueReasons)}
															svgIcon={
																<DischargeIcon
																	height={16}
																	width={16}
																	color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive}
																/>
															}
															text={translate('discontinueMonitoring')}
														/>
													</Dropdown>
												</div>
												{mrn && (
													<FormInput
														text='MRN'
														id='mrn'
														placeholder='MRN'
														type='text'
														value={mrn}
														onChange={() => null}
														className='text-input none-pointer-events'
														maxLength={256}
														readOnly={true}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientName]) && (
													<FormInput
														text={translate('patientName')}
														id='patientName'
														placeholder={intl.formatMessage({ id: 'enterPatientName' })}
														type='text'
														value={mrn ? patientName : patientNotes?.patientName}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, patientName: event.target.value }))}
														className={classNames('text-input', {
															'none-pointer-events': mrn,
														})}
														maxLength={256}
														readOnly={mrn}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientAge]) && (
													<div className='position-relative full-width'>
														<FormInput
															text={translate('patientAge')}
															id='patientAge'
															placeholder={intl.formatMessage({ id: 'enterPatientAge' })}
															type='text'
															value={mrn ? patientAge : patientNotes?.patientAge}
															className={classNames('text-input', {
																'none-pointer-events': mrn,
															})}
															maxLength={256}
															readOnly={true}
														/>
														{!mrn && (
															<div className='patient-notes-date-picker'>
																<DatePicker
																	showIcon={true}
																	icon={<CalendarIcon />}
																	selected={patientNotes?.birthDate}
																	onChange={handleDateChange}
																	dateFormat='MM/dd/yyyy'
																	maxDate={new Date()}
																	isClearable={patientNotes?.birthDate}
																	showMonthDropdown={true}
																	showYearDropdown={true}
																	dropdownMode='select'
																/>
															</div>
														)}
													</div>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PatientGenderIdentity]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'genderIdentity' })}
														defaultOptions={getGenderIdentityDefaultOption()}
														initialOptions={genderIdentityArr}
														onSelect={selectedOptions => {
															setPatientNotes(prevState => ({
																...prevState,
																genderIdentity: +selectedOptions.value,
															}));
														}}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'genderIdentity',
														})}`}
														isDisabled={mrn}
														isMulti={false}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseNameNumber]) && (
													<FormInput
														text={translate('nurseNameNumber')}
														id='nurseNameNumber'
														placeholder={!mrn ? intl.formatMessage({ id: 'enterNurseNameNnumber' }) : ''}
														type='text'
														value={getInputValue(CareTeam.NURSE, CareTeam.NURSE_PHONE, patientNotes?.nurseNameNumber)}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, nurseNameNumber: event.target.value }))
														}
														className={classNames('text-input', {
															'none-pointer-events': mrn,
														})}
														maxLength={256}
														readOnly={mrn}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.NurseAssistantNameNumber]) && (
													<FormInput
														text={translate('nurseAssistantNameNumber')}
														id='nurseAssistantNameNumber'
														placeholder={!mrn ? intl.formatMessage({ id: 'enterNurseAssistantNameNumber' }) : ''}
														type='text'
														value={getInputValue(CareTeam.PCA, CareTeam.PCA_PHONE, patientNotes?.nurseAssistantNameNumber)}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, nurseAssistantNameNumber: event.target.value }))
														}
														className={classNames('text-input', {
															'none-pointer-events': mrn,
														})}
														maxLength={256}
														readOnly={mrn}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TlNameNumber]) && (
													<FormInput
														text={translate('tlNameNumber')}
														id='tlNameNumber'
														placeholder={intl.formatMessage({ id: 'enterTlNameNumber' })}
														type='text'
														value={patientNotes?.tlNameNumber}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, tlNameNumber: event.target.value }))}
														className='text-input'
														maxLength={256}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryAdverseEvent]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'primaryAdverseEvent' })}
														defaultOptions={patientNotes?.primaryAdverseEvent || []}
														initialOptions={primaryAdverseEvent(intl)}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({
																...prevState,
																primaryAdverseEvent: selectedOptions,
															}))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'primaryAdverseEvent',
														})}`}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientCondition]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'primaryPatientCondition' })}
														defaultOptions={patientNotes?.primaryPatientCondition || []}
														initialOptions={primaryPatientCondition(intl)}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({
																...prevState,
																primaryPatientCondition: selectedOptions,
															}))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'primaryPatientCondition',
														})}`}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PrimaryPatientRiskFactor]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'primaryPatientRiskFactor' })}
														defaultOptions={patientNotes?.primaryPatientRiskFactor || []}
														initialOptions={primaryPatientRiskFactor(intl)}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({ ...prevState, primaryPatientRiskFactor: selectedOptions }))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'primaryPatientRiskFactor',
														})}`}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.PreferredLanguage]) && (
													<CustomDropdown
														labelName={intl.formatMessage({ id: 'preferredLanguage' })}
														defaultOptions={patientNotes?.preferredLanguage || []}
														initialOptions={preferredLanguages}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({ ...prevState, preferredLanguage: selectedOptions }))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'preferredLanguage',
														})}`}
														isMulti={false}
														isDisabled={mrn}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.TitleBarNotes]) && (
													<FormInput
														text={translate('titleBarNotes')}
														id='titleBarNotes'
														placeholder={intl.formatMessage({ id: 'enterTitleBarNotes' })}
														type='text'
														value={patientNotes?.titleBarNotes}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, titleBarNotes: event.target.value }))
														}
														className='text-input'
														maxLength={256}
													/>
												)}
												{getConfigurationValue(healthSystemConfigurations[MonitoringSettings.AdditionalPatientNotes]) && (
													<label>
														{translate('additionalPatientNotes')}
														<textarea
															className='text-input'
															maxLength={maxCharacterLength}
															onChange={event => {
																event.preventDefault();
																setPatientNotes(prevState => ({ ...prevState, additionalNotes: event.target.value }));
															}}
															value={patientNotes?.additionalNotes}
															name='note'
															rows={3}
															placeholder={intl.formatMessage({ id: 'enterAdditionalPatientNotes' })}
															style={{ fontSize: '14px' }}
														/>
													</label>
												)}
												<Button text={translate('save')} onClick={handleSubmit} />
											</div>
										</Form>
									)}
								</>
							)}
							<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default PatientNotes;
