import React, { useState, useEffect, useRef, Fragment } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ConfigSettingType, SelectTypes, MonitoringSettings } from 'constants/configurationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import {
	updateRoomSettings,
	updateTeamSettings,
	getRoomsBySectorId,
	getTeamSettings,
	getRoomSettings,
	deleteHealthSystemRoleConfigs,
	setHealthSystemRoleConfigs,
	getHealthSystemRoleConfigs,
} from 'api/adminConfigurations.js';
import { DeviceListLevel } from 'constants/enums.js';
import { EmptyState, Modal } from 'components/index.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import { reorderObjects, getConfigurationValue, getUserRoleId } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import VerbalRedirectionsAdmin from 'components/VerbalRedirectionsAdmin.jsx';
import MonitoringSettingReasonsAdmin from 'components/MonitoringSettingReasonsAdmin.jsx';

const FeatureFlags = props => {
	const intl = useIntl();
	const disabledToggleTimeouts = useRef({});
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const cameraNames = useSelector(state => state.company.companySettings);
	const [roomsToBeOverwritten, setRoomsToBeOverwritten] = useState([]);
	const [selectedConfig, setSelectedConfig] = useState(null);
	const [adminConfigurations, setAdminConfigurations] = useState({});
	const [isLoading, setLoading] = useState(true);
	const [isEmptyState, setIsEmptyState] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);

	const updateConfigAttributes = (config, responseItem) => ({
		...config,
		value: JSON.parse(responseItem.value),
		isLocked: responseItem.isLocked,
		...(responseItem.variant && config.options && { variant: config.options.find(opt => opt.value === responseItem.variant) }),
	});

	const updateRoleConfigsAttributes = (response, childKey, config) => {
		const roleConfig = response.settings.find(config => config.settingTypeId === +childKey);
		return {
			...config,
			value: !!roleConfig,
			...(roleConfig?.variant &&
				config.options && {
					variant: config.options.find(item => item.value === roleConfig.variant),
				}),
		};
	};

	useEffect(() => {
		setLoading(true);
		const fetchSectorSettings = async () => {
			const response =
				props.currentSector !== DeviceListLevel.ROOM
					? await getTeamSettings({
							teamId: props.levelId,
							levelId: props.currentSector,
							settingsCategory: [props.settingCategory],
						})
					: await getRoomSettings(props.levelId, props.settingCategory);

			if (response.error) {
				props.setError(response.error.message);
			} else {
				const featureFlagConfigs = {};
				response.settings.forEach(item => {
					if (props.defaultConfigurations[item.settingTypeId]) {
						const config = updateConfigAttributes(props.defaultConfigurations[item.settingTypeId], item);
						const childrenFeatureFlags = {};
						if (config.childrenConfigs) {
							response.settings.forEach(childConfigItem => {
								if (config.childrenConfigs[childConfigItem.settingTypeId]) {
									const childConfig = config.childrenConfigs[childConfigItem.settingTypeId];
									childrenFeatureFlags[childConfigItem.settingTypeId] = updateConfigAttributes(childConfig, childConfigItem);
								}
							});
							config.childrenConfigs = childrenFeatureFlags;
						}
						featureFlagConfigs[item.settingTypeId] = config;
					}
				});
				if (props.selectedRole) {
					const roleConfigsResponse = await getHealthSystemRoleConfigs(
						props.selectedHealthSystem.value,
						getUserRoleId(props.selectedRole.value)
					);
					if (roleConfigsResponse.error) {
						props.setError(roleConfigsResponse.error.message);
					} else {
						Object.keys(featureFlagConfigs).forEach(key => {
							featureFlagConfigs[key] = updateRoleConfigsAttributes(roleConfigsResponse, key, featureFlagConfigs[key]);
							if (featureFlagConfigs[key].childrenConfigs) {
								Object.keys(featureFlagConfigs[key].childrenConfigs).forEach(childKey => {
									featureFlagConfigs[key].childrenConfigs[childKey] = updateRoleConfigsAttributes(
										roleConfigsResponse,
										childKey,
										featureFlagConfigs[key].childrenConfigs[childKey]
									);
								});
							}
						});
					}
				}

				setAdminConfigurations(featureFlagConfigs);
				setIsEmptyState(Object.keys(featureFlagConfigs).length === 0);
			}
			setLoading(false);
		};

		fetchSectorSettings();
		return () => {
			Object.values(disabledToggleTimeouts.current).forEach(timeout => {
				clearTimeout(timeout);
			});
		};
	}, [props.levelId, props.settingCategory, props.selectedRole]);

	const updateConfigState = (key, settingType, variant, parentKey) => {
		setAdminConfigurations(prevState => {
			const configsCopied = { ...prevState };
			const configItem = parentKey ? configsCopied[parentKey].childrenConfigs[key] : configsCopied[key];
			configItem.value = settingType === ConfigSettingType.TOGGLE ? !configItem.value : configItem.value;
			configItem.isLocked = settingType === ConfigSettingType.LOCK ? !configItem.isLocked : configItem.isLocked;
			if (variant) {
				configItem.variant = configItem.options.find(opt => opt.value === variant);
			}

			if (configItem.disableChildren && !configItem.value && settingType === ConfigSettingType.TOGGLE) {
				Object.keys(configItem.childrenConfigs).forEach(keyToDisable => {
					configItem.childrenConfigs[keyToDisable].value = false;
					configItem.childrenConfigs[keyToDisable].isLocked = false;
				});
			}

			return configsCopied;
		});
	};

	const updateRoomConfiguration = async (setting, settingType, parentKey) => {
		const response = await updateRoomSettings(props.levelId, [setting]);
		const key = setting.settingTypeId;
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType, setting.variant, parentKey);
		}
	};

	const updateTeamsConfigurations = async ({ isOverride, settingType, key, variant, parentKey }) => {
		const item = parentKey ? adminConfigurations[parentKey].childrenConfigs[key] : adminConfigurations[key];
		const teamSettings = [
			{
				settingTypeId: key,
				value: settingType === ConfigSettingType.TOGGLE ? (!item.value).toString() : item.value.toString(),
				isLocked: settingType === ConfigSettingType.LOCK ? !item.isLocked : item.isLocked,
				...(item.variant ? { variant: variant || item.variant?.value } : {}),
			},
		];

		if (item.disableChildren && settingType === ConfigSettingType.TOGGLE && item?.value) {
			Object.keys(item.childrenConfigs).forEach(item => {
				teamSettings.push({
					settingTypeId: item,
					value: 'false',
					isLocked: false,
				});
			});
		}

		const setting = {
			teamSettings,
			teamId: props.levelId,
			isOverride,
			healthSystemId: props.healthSystemId,
		};

		const response = await updateTeamSettings(setting);
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType, setting.teamSettings[0].variant, parentKey);
		}
		setSelectedConfig(null);
	};

	const toggleRoleConfigsItem = async ({ settingType, key, configVariant = null, parentKey }) => {
		let variant = configVariant;
		const roleId = getUserRoleId(props.selectedRole.value);
		const item = parentKey ? adminConfigurations[parentKey].childrenConfigs[key] : adminConfigurations[key];
		const isAddOrUpdate = !getConfigurationValue(item) || (getConfigurationValue(item) && variant);
		if (item && item.options && !variant) {
			variant = item.options[0].value;
		}
		const setting = [
			{
				roleId,
				settingTypeId: +key,
				...(variant && {
					variant,
				}),
			},
		];
		const response = isAddOrUpdate
			? await setHealthSystemRoleConfigs(props.healthSystemId, setting)
			: await deleteHealthSystemRoleConfigs(props.healthSystemId, roleId, key);
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType, variant, parentKey);
		}
	};

	const toggleTeamsConfigsItem = async (settingType, key, parentKey) => {
		const response = await getRoomsBySectorId(props.levelId, key, adminConfigurations[key].value.toString());
		if (response.error) {
			props.setError(response.error.message);
			return;
		}
		if (response.rooms.length > 0) {
			setRoomsToBeOverwritten(response.rooms);
			setSelectedConfig(key);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType, key, variant: null, parentKey });
		}
	};

	const toggleRoomConfigsItem = (settingType, key, parentKey) => {
		const item = parentKey ? adminConfigurations[parentKey].childrenConfigs[key] : adminConfigurations[key];
		const setting = {
			settingTypeId: key,
			value: (!item.value).toString(),
			isLocked: item.isLocked,
			...(item.variant ? { variant: item.variant?.value } : {}),
		};

		updateRoomConfiguration(setting, settingType, parentKey);
	};

	const setAvailabilityOfToggle = (status, key, parentKey) =>
		setAdminConfigurations(prevState => {
			const configsCopied = { ...prevState };
			const configItem = parentKey ? configsCopied[parentKey].childrenConfigs[key] : configsCopied[key];
			configItem.isDisabled = status;
			return configsCopied;
		});

	const toggleItem = (key, parentKey) => {
		const settingType = ConfigSettingType.TOGGLE;
		clearTimeout(disabledToggleTimeouts.current[key]);

		setAvailabilityOfToggle(true, key, parentKey);
		disabledToggleTimeouts.current[key] = setTimeout(() => {
			setAvailabilityOfToggle(false, key, parentKey);
		}, 1000);

		if (props.currentSector === DeviceListLevel.HEALTH_SYSTEM && !props.selectedRole) {
			updateTeamsConfigurations({ isOverride: false, settingType, key, variant: null, parentKey });
			return;
		}

		if (props.selectedRole) {
			toggleRoleConfigsItem({ settingType, key, configVariant: null, parentKey });
			return;
		}

		if (props.currentSector !== DeviceListLevel.ROOM) {
			toggleTeamsConfigsItem(settingType, key, parentKey);
		} else {
			toggleRoomConfigsItem(settingType, key, parentKey);
		}
	};

	const customizeConfig = (key, variant, parentKey) => {
		const item = adminConfigurations[key];

		if (item.type === SelectTypes.ROLE_SETTINGS) {
			toggleRoleConfigsItem({ settingType: ConfigSettingType.SELECT, key, configVariant: variant, parentKey });
			return;
		}

		if (props.currentSector === DeviceListLevel.ROOM) {
			const setting = { settingTypeId: key, value: item.value.toString(), isLocked: item.isLocked, variant };
			updateRoomConfiguration(setting, ConfigSettingType.SELECT, parentKey);
		} else {
			updateTeamsConfigurations({
				isOverride: true,
				settingType: ConfigSettingType.SELECT,
				key,
				variant,
				parentKey,
			});
		}
	};

	const toggleLock = async (key, parentKey) => {
		const item = parentKey ? adminConfigurations[parentKey].childrenConfigs[key] : adminConfigurations[key];
		const setting = {
			settingTypeId: key,
			value: item.value.toString(),
			isLocked: !item.isLocked,
			...(item.variant ? { variant: item.variant?.value } : {}),
		};

		if (props.currentSector === DeviceListLevel.ROOM) {
			updateRoomConfiguration(setting, ConfigSettingType.LOCK, parentKey);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType: ConfigSettingType.LOCK, key, variant: null, parentKey });
		}
	};

	const transformArray = array => array.map(item => ({ value: item.value, label: intl.formatMessage({ id: item.label }) }));

	const isLockEditable = () =>
		[DeviceListLevel.ROOM, DeviceListLevel.FLOOR, DeviceListLevel.DEPARTMENT, DeviceListLevel.HOSPITAL].includes(
			props.currentSector
		);

	const groupedCategories = () =>
		Object.entries(adminConfigurations).reduce((acc, [key, item]) => {
			const { category } = item;
			if (!acc[category]) {
				acc[category] = [];
			}

			if (!item.conditionalDependencies || checkDependencyFromSameCategory(item.conditionalDependencies)) {
				acc[category].push([key, item]);
			}

			return acc;
		}, []);

	const checkDependencyFromSameCategory = conditionsObj => {
		const everyConfigsToCheck = conditionsObj.everyConfig
			? conditionsObj.everyConfig.map(item => adminConfigurations[item]).filter(config => config)
			: [];
		const sameCategorySomeToCheck = conditionsObj.someConfigs?.filter(item => adminConfigurations[item]);
		const atLeastOneEnabled =
			sameCategorySomeToCheck.some(item => adminConfigurations[item]?.value) || sameCategorySomeToCheck.length === 0;
		return everyConfigsToCheck.length === 0 ? atLeastOneEnabled : everyConfigsToCheck.every(config => config.value);
	};

	const featureFlagsCategories = () =>
		props.categoryOrder ? reorderObjects(groupedCategories(), props.categoryOrder) : groupedCategories();

	const setDetailRows = key =>
		setExpandedRows(prevExpandedRows =>
			prevExpandedRows.includes(key) ? prevExpandedRows.filter(row => row !== key) : [...prevExpandedRows, key]
		);

	const renderFeatureFlag = (key, item, parentKey) => (
		<Fragment>
			<div className='feature-flag flex' key={item.title}>
				<div className='toggle-config'>
					{isLockEditable() && (
						<i
							className='material-icons cursor-pointer right-s opacity-transformation'
							onClick={() => toggleLock(+key, +parentKey)}>
							{item.isLocked ? 'lock' : 'lock_open'}
						</i>
					)}
					<div
						className={classNames('rounded-slider-switch', { disabled: item.isDisabled })}
						onClick={() => toggleItem(+key, +parentKey)}>
						<input type='checkbox' checked={item.value} onChange={() => null} />
						<span className='rounded-slider' />
					</div>
					<p>{item.value ? translate('on') : translate('off')}</p>
				</div>
				<div className='feature-description full-width'>
					<p className='flex-1 ai-feature-title'>{translate(item.title)}</p>
					<div className='flex flex-space-between'>
						<p className='margin-top-m'>
							{translate(item.description, {
								value: translate(props.selectedRole?.value?.toLowerCase()) ?? 'user',
								huddleName: cameraNames.huddleName,
								helloName: cameraNames.helloName,
							})}
						</p>
					</div>
					{item.options && item.value && !item.hasButton && (
						<div className='feature-flag-options flex'>
							<p>{translate('customize')}</p>
							<Select
								value={transformArray([item.variant])}
								classNamePrefix='react-select'
								options={transformArray(item.options)}
								onChange={event => customizeConfig(key, event.value, parentKey)}
							/>
						</div>
					)}
					{+key === MonitoringSettings.VerbalRedirection && item.value && item.isExpandable && (
						<VerbalRedirectionsAdmin
							healthSystemId={props.healthSystemId}
							setError={props.setError}
							shouldShowExpanded={expandedRows.includes(+key)}
						/>
					)}
					{+key === MonitoringSettings.DiscontinueMonitoring && item.value && item.isExpandable && (
						<MonitoringSettingReasonsAdmin
							healthSystemId={props.healthSystemId}
							setError={props.setError}
							shouldShowExpanded={expandedRows.includes(+key)}
							title='discontinueMonitoring'
							settingTypeId={MonitoringSettings.DiscontinueMonitoring}
							placeholder={intl.formatMessage({ id: 'addNewReason' })}
						/>
					)}
					{+key === MonitoringSettings.PatientOutOfRoom && item.value && item.isExpandable && (
						<MonitoringSettingReasonsAdmin
							healthSystemId={props.healthSystemId}
							setError={props.setError}
							shouldShowExpanded={expandedRows.includes(+key)}
							title='patientOutOfRoom'
							settingTypeId={MonitoringSettings.PatientOutOfRoom}
							placeholder={intl.formatMessage({ id: 'addNewReason' })}
						/>
					)}
				</div>
				{item.value && item.isExpandable && (
					<span className='flex cursor-pointer margin-left-auto expand-ff' onClick={() => setDetailRows(+key)}>
						<i className='material-icons-outlined'>{expandedRows.includes(+key) ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
					</span>
				)}
			</div>
			{item.childrenConfigs && item.value && expandedRows.includes(+key) && (
				<div className='feature-flags-category children-configs'>
					{item.dependentConfigsTitle && <p className='feature-dependent-title'>{translate(item.dependentConfigsTitle)}</p>}
					{Object.entries(item.childrenConfigs).map(([childKey, childItem]) => renderFeatureFlag(childKey, childItem, key))}
				</div>
			)}
		</Fragment>
	);

	return (
		<div className='feature-flags'>
			{props.currentSector === DeviceListLevel.HEALTH_SYSTEM && (
				<div className='feature-flags-header'>
					<div>
						<h4>{translate('selectHealthSystem')}</h4>
						<Select
							value={props.selectedHealthSystem}
							classNamePrefix='react-select'
							options={allHealthSystems.map(item => ({ value: item.id, label: item.name || item.value }))}
							onChange={props.setSelectedHealthSystem}
						/>
					</div>
					{props.selectedRole && (
						<div>
							<h4>{translate('selectRole')}</h4>
							<Select
								value={props.selectedRole}
								classNamePrefix='react-select'
								options={props.roleList}
								onChange={props.setSelectedRole}
							/>
						</div>
					)}
				</div>
			)}
			{!isLoading &&
				Object.keys(featureFlagsCategories()).map(category => (
					<div className='feature-flags-category' key={category}>
						{groupedCategories()[category].length > 0 && props.categoryOrder && <h4>{translate(category)}</h4>}
						{groupedCategories()[category]?.map(([key, item]) => renderFeatureFlag(key, item))}
					</div>
				))}
			{!isLoading && isEmptyState && <EmptyState title={translate('pleaseReachAdmin')} image='no-files.svg' />}
			{isLoading && <SkeletonLoader rows={10} padding='35px 20px' />}
			<Modal
				position='center'
				modalSelector='confirmOverwritingAdminConfigs'
				onModalClose={() => setSelectedConfig(null)}
				className='confirm-overwrite-configs'
				display={selectedConfig}
				primaryButtonLabel={translate('applyToAll')}
				onModalSubmit={() =>
					updateTeamsConfigurations({
						isOverride: true,
						settingType: ConfigSettingType.TOGGLE,
						key: selectedConfig,
						variant: null,
						parentKey: null,
					})
				}
				isThirdButtonShown
				onThirdButtonClick={() =>
					updateTeamsConfigurations({
						isOverride: false,
						settingType: ConfigSettingType.TOGGLE,
						key: selectedConfig,
						variant: null,
						parentKey: null,
					})
				}
				thirdButtonLabel={translate('exclude')}>
				<div className='overwrite-configs-content'>
					<h2>
						{translate('warning')} - {translate('featureOverwrite')}
					</h2>
					<p>{translate('waringOverwriteDescription')}</p>
					<h4>{translate('roomsWithDifferentConfigs')}:</h4>
					<div className='rooms-list flex'>
						{roomsToBeOverwritten.map(item => (
							<div key={item.room.id}>
								<img className='device-owner-ico' src={`${healthCareCdnUrl}treeview/Room.svg`} alt='ico' />
								<span>{item.room.name}</span>
							</div>
						))}
					</div>
					<p>{translate('bySavingThisDescription')}</p>
				</div>
			</Modal>
		</div>
	);
};

export default FeatureFlags;
