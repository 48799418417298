import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { Button, Input, PopUpAlert } from 'components/index.js';
import { AlertTypes } from 'constants/enums.js';
import { reorderObjects } from 'infrastructure/helpers/commonHelpers.js';
import { urlRegex } from 'infrastructure/helpers/validationHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import StatAlarms from 'components/StatAlarms.jsx';
import {
	AiSetting,
	AiSettingFeaturesCategory,
	CallWorkflowType,
	MonitoringSettings,
	configurableWorkflowTypes,
	getConfigurationWithDiffTypeKey,
} from 'constants/configurationEnums.js';
import classNames from 'classnames';
import AiConfigurationFeatureFlags from 'components/AiConfigurationFeatureFlags.jsx';
import { prepareAiConfigsForSubmit, validateAiConfigurations } from 'infrastructure/helpers/aiHelper.js';
import { updateOrganizationSettings } from 'api/companies.js';
import { AiConfigurationSettings } from 'constants/ai.js';

const CreateEditFeatureFlags = props => {
	const intl = useIntl();
	const [featureFlags, setFeaturesFlags] = useState(props.dataToSubmit[props.settingCategory]);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [alertType, setAlertType] = useState(null);
	const [isStatAlarmModalOpen, setIsStatAlarmModalOpen] = useState(false);
	const [selectedType, setSelectedType] = useState(
		props.featureTypes
			? {
					value: props.featureTypes[0].value,
					label: intl.formatMessage({ id: props.featureTypes[0].label }),
			  }
			: null
	);
	const [expandedRows, setExpandedRows] = useState([]);
	const [aiErrors, setAiErrors] = useState([]);
	const [isAiError, setIsAiError] = useState(false);

	useEffect(() => {
		if (props.featureTypes) {
			const combinedFeatureFlags = Object.keys(featureFlagsCategories()).reduce((acc, category) => {
				const evidenceFeatureFlags = Object.fromEntries(
					Object.entries(props.dataToSubmit[props.settingCategory]).filter(([key]) =>
						[AiSetting.ALERTS_EVIDENCE_ADMIN, AiSetting.ALERTS_EVIDENCE_NURSE].includes(+key)
					)
				);

				const filtered =
					category !== AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS ? filterConfigsByType(selectedType.value) || {} : {};
				return { ...acc, ...evidenceFeatureFlags, ...filtered };
			}, {});
			setFeaturesFlags(combinedFeatureFlags);
		}
	}, [selectedType]);

	const checkAiSettingsValidation = async (aiSettingsFeatureFlags, callWorkflowTypeId) => {
		const errorsArr = [];

		AiConfigurationSettings.forEach(async setting => {
			const aiSettingType = aiSettingsFeatureFlags[getConfigurationWithDiffTypeKey(setting, callWorkflowTypeId)];
			if (aiSettingType?.value) {
				const aiConfigErrors = validateAiConfigurations(aiSettingType, intl);
				if (Object.keys(aiConfigErrors.errors).length > 0) {
					errorsArr.push(aiConfigErrors);
					setDetailRows(
						+getConfigurationWithDiffTypeKey(setting, callWorkflowTypeId),
						!expandedRows.includes(+getConfigurationWithDiffTypeKey(setting, callWorkflowTypeId))
					);
				}
			}
		});
		return errorsArr;
	};

	const checkErrorsPerWorkflowType = async aiSettingsFeatureFlags => {
		for (let callWorkflowTypeId of [CallWorkflowType.MONITORING, CallWorkflowType.ROUNDING, CallWorkflowType.BACKGROUND]) {
			const errors = await checkAiSettingsValidation(aiSettingsFeatureFlags, callWorkflowTypeId);
			if (errors.length > 0) {
				if (callWorkflowTypeId !== selectedType?.value) {
					const foundWorkflowType = configurableWorkflowTypes()?.find(item => item.value === callWorkflowTypeId);
					setSelectedType(foundWorkflowType);
				}
				return errors;
			}
		}
		return null;
	};

	const getValueForPayload = item => {
		let configValue = item.isURLField ? item.value || 'false' : item.value;
		if (item?.conditionalDependencies?.someConfigs) {
			item.conditionalDependencies.dependedFromConfigCategories.forEach(category => {
				configValue =
					configValue || item.conditionalDependencies.someConfigs.some(itemKey => props.dataToSubmit[category]?.[itemKey]?.value);
			});
		}
		return configValue.toString();
	};

	const onSubmit = async e => {
		e.preventDefault();
		setAiErrors([]);
		setIsAiError(false);
		const aiSettingsFeatureFlags = props.dataToSubmit.aiSettings;
		const foundErrors = await checkErrorsPerWorkflowType(aiSettingsFeatureFlags || []);
		if (aiSettingsFeatureFlags && foundErrors) {
			setAiErrors(foundErrors);
			setIsAiError(true);
			return;
		}
		if (!props.selectedCompany) {
			props.setDataToSubmit(featureFlags);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);

			const flattenConfigs = data => {
				const recurse = obj =>
					Object.keys(obj).reduce((acc, key) => {
						const config = obj[key];
						const flattenedItem = {
							settingTypeId: config.settingTypeId ? +config.settingTypeId : +key,
							value: getValueForPayload(config),
							...(config.variant && { variant: config.variant.value }),
							...(config.roomTypeId && { roomTypeId: config.roomTypeId }),
							...(config.callWorkflowTypeId && { callWorkflowTypeId: config.callWorkflowTypeId }),
							...(config.aiConfigurationTypeId &&
								config.value && {
									aiConfigurations: prepareAiConfigsForSubmit(config.aiConfigurations),
								}),
						};
						const children = config.childrenConfigs ? recurse(config.childrenConfigs) : [];
						return acc.concat(flattenedItem, children);
					}, []);

				return Object.keys(data).reduce((acc, category) => acc.concat(recurse(data[category])), []);
			};

			const settingsParams = {
				companySettings: flattenConfigs(props.dataToSubmit),
				companyId: props.selectedCompany.id,
			};

			const response = await updateOrganizationSettings(settingsParams);
			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(featureFlags);
			} else {
				setAlertType(AlertTypes.DANGER);
				if (props.featureTypes) {
					const filtered = filterConfigsByType(selectedType?.value);
					setFeaturesFlags(filtered);
				} else {
					setFeaturesFlags(props.dataToSubmit[props.settingCategory]);
				}
			}

			setIsSaveLoading(false);
		}
	};

	const toggleValue = (item, value = !item.value) => {
		item.value = value;
		if (item.variant && item.options && item.value) {
			item.variant = item.options[0];
		}
	};

	const disableDependedConfigs = (key, config) => {
		if (config[key]?.otherConfigToDisable) {
			config[key].otherConfigToDisable.forEach(dep => toggleValue(config[dep], false));
		}
		if (config[key]?.disableChildren) {
			Object.keys(config[key].childrenConfigs).forEach(childKey => toggleValue(config[key].childrenConfigs[childKey], false));
		}
	};

	const toggleItem = (key, parentKey) => {
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			const copiedConfig = parentKey ? { ...configsCopied[parentKey].childrenConfigs[key] } : { ...configsCopied[key] };
			toggleValue(copiedConfig);
			disableDependedConfigs(key, configsCopied);
			return { ...prevState, ...configsCopied };
		});

		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		const createCompanyConfig = parentKey ? createCompanyState[parentKey].childrenConfigs[key] : createCompanyState[key];
		toggleValue(createCompanyConfig);
		setDetailRows(key, !createCompanyConfig.value);
		disableDependedConfigs(key, createCompanyState);
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const customizeConfig = (key, variant, parentKey) => {
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			const copiedConfig = parentKey ? configsCopied[parentKey].childrenConfigs[key] : configsCopied[key];
			copiedConfig.variant = copiedConfig.options.find(opt => opt.value === variant);

			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key].variant = createCompanyState[key].options.find(opt => opt.value === variant);
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const handleURLInput = (key, value) => {
		const validURL = urlRegex.test(value);
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key].value = value.trim() ? value : 'false';
			configsCopied[key].valid = validURL;

			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key].value = value;
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const transformArray = array => array.map(item => ({ value: item.value, label: intl.formatMessage({ id: item.label }) }));

	const groupedCategories = () =>
		Object.entries(featureFlags).reduce((acc, [key, item]) => {
			const { category } = item;
			if (!acc[category]) {
				acc[category] = [];
			}

			if (!item.conditionalDependencies || checkDependencyCondition(item.conditionalDependencies)) {
				acc[category].push([key, item]);
			}
			return acc;
		}, []);

	const checkDependencyCondition = conditionsObj => {
		const everyConfigsToCheck = [];
		let atLeastOneEnabled = false;
		conditionsObj.dependedFromConfigCategories.forEach(category => {
			if (!atLeastOneEnabled && conditionsObj.someConfigs) {
				atLeastOneEnabled = conditionsObj.someConfigs.some(item => props.dataToSubmit[category]?.[item]?.value);
			}
			if (conditionsObj.everyConfig) {
				conditionsObj.everyConfig.forEach(item => {
					if (props.dataToSubmit[category][item]) {
						everyConfigsToCheck.push(props.dataToSubmit[category][item]);
					}
				});
			}
		});

		return everyConfigsToCheck.length === 0 ? atLeastOneEnabled : everyConfigsToCheck.every(item => item.value);
	};

	const featureFlagsCategories = () =>
		props.categoryOrder ? reorderObjects(groupedCategories(), props.categoryOrder) : groupedCategories();

	const filterConfigsByType = type =>
		Object.keys(props.dataToSubmit[props.settingCategory]).reduce((acc, key) => {
			if (
				props.dataToSubmit[props.settingCategory][key].roomTypeId === type ||
				props.dataToSubmit[props.settingCategory][key].callWorkflowTypeId === type
			) {
				acc[key] = props.dataToSubmit[props.settingCategory][key];
			}
			return acc;
		}, {});

	const handleAiConfigsChange = ({ key, aiConfigs }) => {
		if (!key || !aiConfigs) {
			return;
		}
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key]['aiConfigurations'] = aiConfigs;
			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key]['aiConfigurations'] = aiConfigs;
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const setDetailRows = (key, value = true) => {
		setExpandedRows(prevExpandedRows =>
			prevExpandedRows.includes(key) && value ? prevExpandedRows.filter(row => row !== key) : [...prevExpandedRows, key]
		);
	};

	const renderFeatureFlag = (item, key, parentKey) => (
		<Fragment key={key}>
			<div className='feature-flag flex' key={key}>
				{!item.isURLField && (
					<div className='toggle-config'>
						<div className='rounded-slider-switch' onClick={() => toggleItem(+key, +parentKey)}>
							<input type='checkbox' checked={item.value === 'true' || item.value === true} onChange={() => null} />
							<span className='rounded-slider' />
						</div>
						<p>{item.value ? translate('on') : translate('off')}</p>
					</div>
				)}
				<div className={classNames('feature-description', { 'no-toggle-shown': item.isURLField })}>
					<p className='flex-1'>{translate(item.title)}</p>
					<p>
						{translate(item.description, {
							value: 'user',
							huddleName: props.cameraNames?.huddleName,
							helloName: props.cameraNames?.helloName,
						})}
					</p>
					{item.options && item.value && !item.hasButton && (
						<div className='feature-flag-options flex'>
							<p>{translate('customize')}</p>
							<Select
								value={transformArray([item.variant])}
								classNamePrefix='react-select'
								options={transformArray(item.options)}
								onChange={event => customizeConfig(key, event.value, parentKey)}
							/>
						</div>
					)}
					{item.hasButton && item.value && (
						<div className='flex flex-align-center'>
							{item.variant?.value && (
								<div className='flex flex-space-between stat-alarm-details selected-alarm cursor-pointer active'>
									<div className='flex flex-align-center full-width'>
										<i className='material-icons-outlined'>notifications_active</i>
										<span className='font-14 left-s --blue-light-5'>
											{intl.formatMessage({ id: item.variant?.label }, { value: item.variant?.translateValue })}
										</span>
									</div>
								</div>
							)}
							<div className='flex stat-alarm-flag cursor-pointer' onClick={() => setIsStatAlarmModalOpen(true)}>
								<img src={`${healthCareCdnUrl}icon/change.svg`} alt='icon' />
								<span>{translate('selectOtherAlarm')}</span>
							</div>
						</div>
					)}
					{item.isURLField && (
						<div className='feature-flag-text-input'>
							<Input
								type='text'
								placeholder={`http://example.com ${intl.formatMessage({ id: 'or' })} https://example.com`}
								value={item.value === 'false' ? '' : item.value}
								inputWidth='300px'
								onChange={event => handleURLInput(key, event.target.value)}
								name='item.title'
							/>
							{!!item.value && !item?.valid && <span className='red-error'>{translate('badURL')}</span>}
						</div>
					)}
					{item.aiConfigurationTypeId && (
						<div className='flex'>
							{expandedRows.includes(+key) && (
								<AiConfigurationFeatureFlags
									onAiConfigsChange={handleAiConfigsChange}
									settingTypeId={key}
									aiErrors={aiErrors}
									selectedAiConfig={item}
								/>
							)}
						</div>
					)}
				</div>
				{item.isExpandable && (
					<span className='flex cursor-pointer margin-left-auto expand-ff' onClick={() => setDetailRows(+key)}>
						<i className='material-icons-outlined'>{expandedRows.includes(+key) ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
					</span>
				)}
			</div>
			{item.childrenConfigs && expandedRows.includes(+key) && (
				<div className={classNames('feature-flags-category children-configs', { disabled: !item.value })}>
					{item.dependentConfigsTitle && <p className='feature-dependent-title'>{translate(item.dependentConfigsTitle)}</p>}
					{Object.entries(item.childrenConfigs).map(([childKey, childItem]) => renderFeatureFlag(childItem, childKey, key))}
				</div>
			)}
		</Fragment>
	);

	return (
		<div>
			{Object.keys(featureFlagsCategories())
				.filter(category => category === AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS)
				.map(category => (
					<div className='feature-flags-category' key={category}>
						{category !== props.settingCategory && <h4>{translate(category)}</h4>}
						{groupedCategories()[category]?.map(([key, item]) => renderFeatureFlag(item, key))}
					</div>
				))}
			{props.featureTypes && (
				<div className='feature-flags-header'>
					<div>
						<h4>{translate(props.typeTitle)}</h4>
						<Select
							value={selectedType}
							classNamePrefix='react-select'
							options={transformArray(props.featureTypes)}
							onChange={val => {
								setExpandedRows([]);
								setSelectedType(val);
							}}
						/>
					</div>
				</div>
			)}
			{Object.keys(featureFlagsCategories())
				.filter(category => category !== AiSettingFeaturesCategory.EVIDENCE_CONFIGURATIONS)
				.map(category => (
					<div className='feature-flags-category' key={category}>
						{category !== props.settingCategory && <h4>{translate(category)}</h4>}
						{groupedCategories()[category]?.map(([key, item]) => renderFeatureFlag(item, key))}
					</div>
				))}
			<div className='create-hs__add'>
				{!props.selectedCompany && (
					<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} disabled={!props.isValid} />
				)}
				<Button
					type='submit'
					text={translate(props.selectedCompany ? 'save' : 'nextStep')}
					onClick={onSubmit}
					isLoading={isSaveLoading}
				/>
			</div>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
			{aiErrors.length > 0 && props.currentTab !== 5 && (
				<PopUpAlert
					alertType={AlertTypes.DANGER}
					display={isAiError}
					onAlertClose={() => setIsAiError(false)}
					contentText={intl.formatMessage({ id: 'fillRequiredFieldsAi' })}
					isSilent={true}
					center={true}
					selfCloseTimeOut={1500}
				/>
			)}
			<StatAlarms
				isStatAlarmModalOpen={isStatAlarmModalOpen}
				setIsStatAlarmModalOpen={setIsStatAlarmModalOpen}
				customizeConfig={customizeConfig}
				config={props.dataToSubmit[props.settingCategory][MonitoringSettings.StatAlarm]}
			/>
		</div>
	);
};

export default CreateEditFeatureFlags;
