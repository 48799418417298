export const groupTypes = {
	DEFAULT: { id: 1, title: 'default' },
	ECM: { id: 2, title: 'ecm' },
	SMART_FHIR: { id: 3, title: 'smartFHIR' },
	NURSE_CALLING: { id: 4, title: 'nurseCalling' },
	TRANSLATION_SERVICES: { id: 5, title: 'interpretationServices' },
	PATIENT_INFOTAINMENT: { id: 6, title: 'patientInfotainment' },
};

export const configurationTypeIds = {
	ECARE_API_KEY: 1,
	ECARE_BASE_URL: 2,
	EPIC: 3,
	CERNER: 4,
	MEDPLUM: 5, 
	GLOBO: 6,
	AMN: 7,
	HILL_ROM: 8,
	LANGUAGE_LINE: 9,
	MOVIES: 11,
	LIVE_TV_CHANNELS: 12,
	MUSIC: 13,
};
