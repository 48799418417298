import {
	CompanyCallSettings,
	ConfigurableDigitalBackgroundSettings,
	MonitoringSettings,
	PatientTabs,
	RoomModesSettings,
	RouteSetting,
	configurableRoomType,
	defaultConfigurableMenu,
} from 'constants/configurationEnums.js';
import _ from 'lodash';
import { getConfigurationMenu, getConfigurationValue, isJSON, stringToCamelCase } from './commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { CompanyRoleObjects, UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';

export const findConfigurationValue = (list, typeId) =>
	list.find(x => x.settingTypeId === typeId)?.value.toLowerCase() === 'true';

export const buildVisualsSettings = newSettings => {
	const defaultSettings = _.cloneDeep(ConfigurableDigitalBackgroundSettings);
	newSettings.forEach(item => {
		if (defaultSettings[item.settingTypeId]) {
			defaultSettings[item.settingTypeId].value = item.value;
			defaultSettings[item.settingTypeId].isLocked = item.isLocked;
		}
	});
	return defaultSettings;
};

export const buildHealthSystemConfigurations = settingsResponse => {
	const healthSystemConfigsList = [...Object.values(MonitoringSettings), ...Object.values(PatientTabs)];
	const healthSystemConfigs = {};
	settingsResponse.forEach(config => {
		if (healthSystemConfigsList.includes(config.settingTypeId)) {
			healthSystemConfigs[config.settingTypeId] = {
				value: JSON.parse(config.value),
				...(config.variant && { variant: config.variant }),
			};
		}
	});

	return healthSystemConfigs;
};

export const buildCallSettings = settings => {
	const result = {};
	Object.entries(CompanyCallSettings).forEach(([, value]) => {
		const found = settings.find(item => item.settingTypeId === value);

		if (found && !isJSON(found.value)) {
			result[value] = found.value;
		} else {
			result[value] = found ? JSON.parse(found.value) : false;
		}
	});
	return result;
};

export const getAdminGeneralSettingConfigValue = (adminConfigurableMenu, settingTypeId) => {
	const adminConfigs = getConfigurationMenu(adminConfigurableMenu, getUserRole());
	return getConfigurationValue(adminConfigs[settingTypeId]);
};

export const buildAllConfigurations = (response = []) => {
	const result = {};
	response.forEach(item => {
		if (!item.roomTypeId) {
			result[item.settingTypeId] = {
				value: ['true', 'false'].includes(item.value) ? JSON.parse(item.value) : item.value,
				isLocked: item.isLocked,
				...(item.variant && { variant: item.variant }),
			};
		}
	});

	return result;
};

export const buildRoomModesConfigurations = (response = []) => {
	const result = {};
	const enabledByDefault = [RoomModesSettings.HELLOCARE];

	response.forEach(item => {
		if (item.roomTypeId) {
			result[`${item.settingTypeId}${item.roomTypeId}`] = {
				value: JSON.parse(item.value),
				isLocked: item.isLocked,
				roomTypeId: item.roomTypeId,
			};
		}
	});

	configurableRoomType().forEach(type => {
		enabledByDefault.forEach(mode => {
			result[`${mode}${type.value}`] = {
				value: true,
				isLocked: false,
				roomTypeId: type.value,
			};
		});
	});

	return result;
};

export const mapCompanyRouteSettings = settings =>
	settings.filter(item => Object.values(RouteSetting).includes(item.settingTypeId));

export const getConfiguredRoleDisplayName = (roleName, companySettings) => {
	switch (roleName) {
		case UserRoles.NURSE:
			return companySettings.nurseDisplayName;
		case UserRoles.DOCTOR:
			return companySettings.doctorDisplayName;
		default:
			return translate(stringToCamelCase(roleName));
	}
};

export const buildRoleSettings = async (newSettings, defaultConfigurations = {}, role) => {
	const buildSettings = {};
	Object.values(defaultConfigurations).forEach(key => {
		const roundingConfig = newSettings.some(config => config.settingTypeId === +key && config.roleId === role);
		buildSettings[key] = roundingConfig;
	});
	return buildSettings;
};

export const buildMenuConfigsPerRole = (preferences, configurableMenu) => {
	const newConfig = {};
	if (!configurableMenu) {
		return null;
	}
	Object.keys(configurableMenu).forEach(key => {
		const foundPreference = preferences.find(preference => preference.preferenceTypeId === +key);
		if (foundPreference) {
			newConfig[key] = { ...configurableMenu[key], value: foundPreference.value };
		} else {
			newConfig[key] = { ...configurableMenu[key], value: true };
		}
	});
	return newConfig;
};

export const buildHSMenuConfigsForAllRoles = async settings => {
	const result = defaultConfigurableMenu(false);
	CompanyRoleObjects.forEach(item => {
		const filteredSettings = settings.filter(setting => setting.roleId === item.id);
		filteredSettings.forEach(setting => {
			if (result[item.role][setting.settingTypeId]) {
				result[item.role][setting.settingTypeId] = {
					...result[item.role][setting.settingTypeId],
					value: true,
					...(setting.variant && {
						variant: setting.variant,
					}),
				};
			}
		});
	});
	return result;
};
