import React, { useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { SingleValue, formatOptionLabel } from 'infrastructure/helpers/careEventsHelper.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import RedAlert from 'icons/Monitoring/RedAlert.jsx';
import Alert from 'components/Alert.jsx';
import { getMembers } from 'api/users.js';
import { CompanyRoles, MembersType } from 'constants/enums.js';
import { checkHandoverPermissions } from 'api/monitoring.js';

const TransferFeeds = props => {
	const healthSystems = useSelector(state => state.healthSystems);
	const [nurses, setNurses] = useState([]);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [noPermissionRooms, setNoPermissionRooms] = useState([]);
	const [error, setError] = useState('');

	const intl = useIntl();

	const getNurses = async (value = '') => {
		const params = {
			searchValue: value,
			roles: [CompanyRoles.VIRTUAL_CARE_PROVIDER, CompanyRoles.VIRTUAL_SITTER],
			filterType: MembersType.ALL_MEMBERS,
			healthSystems: healthSystems.allHealthSystems?.length > 0 ? healthSystems.allHealthSystems.map(item => item.id) : [],
			...(value && {
				pageSize: 50,
				pageIndex: 0,
			}),
		};
		const response = await getMembers(params);
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		const newOptions = response.members.map(user => ({
			value: user?.userInfo?.id,
			label: `${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`,
			email: user?.userInfo?.email,
			profilePicture: user?.userInfo?.profilePicture,
		}));
		setNurses(newOptions);
		return newOptions;
	};

	const checkPermission = async nurseId => {
		setNoPermissionRooms([]);
		const response = await checkHandoverPermissions(
			nurseId,
			props.feeds.map(item => item.roomId)
		);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.roomsWithoutPermission.length === 0) {
			props.submitTransfer(nurseId);
			return;
		}
		const roomsWithoutPermission = response.roomsWithoutPermission.reduce((acc, roomId) => {
			const match = props.feeds.find(item => item.roomId === roomId);
			if (match) {
				acc.push(`Room ${match.roomName}`);
			}
			return acc;
		}, []);
		setNoPermissionRooms(roomsWithoutPermission);
	};

	return (
		<>
			<Modal
				display={true}
				position='center'
				onModalClose={props.onClose}
				onModalSubmit={() => {
					if (noPermissionRooms.length > 0) {
						props.submitTransfer(selectedNurse?.value);
						return;
					}
					checkPermission(selectedNurse?.value);
				}}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal reorder-feeds-modal'
				primaryButtonLabel={props.intl.formatMessage({ id: 'transfer' })}
				shouldSubmitOnEnter={false}
				isSubmitDisabled={!selectedNurse?.value}>
				<Form height={300} className='reorder-form transfer-form overflow-auto'>
					<div className='reorder-feeds-wrapper'>
						<h3 className='margin-bottom-l'>{translate('handoverTo')}</h3>
						<label className='care-events-select'>
							<label onClick={() => getNurses('')}>
								<AsyncSelect
									loadOptions={getNurses}
									placeholder={intl.formatMessage({ id: 'selectSitterNurse' })}
									onChange={value => {
										setNoPermissionRooms([]);
										setSelectedNurse(value);
									}}
									getOptionValue={option => option.value}
									classNamePrefix='custom-select'
									defaultOptions={nurses}
									isClearable
									value={selectedNurse}
									formatOptionLabel={formatOptionLabel}
									components={{ SingleValue }}
									styles={generateCustomStyles({ darkMode: props.isDarkMode })}
								/>
							</label>
						</label>
						{selectedNurse && noPermissionRooms.length > 0 && (
							<div className='no-permission-rooms'>
								<div>
									<div className='flex-shrink-0'>
										<RedAlert />
									</div>
									<div>
										<p>
											{selectedNurse.label} {translate('noAccessFollowingRooms')}
										</p>
										<p>{noPermissionRooms.map(item => item).join(', ')}</p>
									</div>
								</div>
								<p>{translate('accessMessageNotification')}</p>
							</div>
						)}
					</div>
				</Form>
			</Modal>
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default injectIntl(TransferFeeds);
