import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { SettingsCategory, PatientTabs, GeneralAndMenuSettings } from 'constants/configurationEnums.js';
import { UserRoles } from 'constants/enums.js';
import FeatureFlags from 'components/FeatureFlags.jsx';
import { useSelector } from 'react-redux';
import AiFeatureFlags from 'components/AiFeatureFlags.jsx';
import { getConfigurationValue, getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';

const FeatureFlagsCategories = props => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const isUserExperienceHSEditable = getConfigurationValue(
		companyConfigurations[GeneralAndMenuSettings.IS_EXPERIENCE_HS_CHANGEABLE]
	);
	const anyPatientTabEnabled = getSomeConfigurationsValues(companyConfigurations, Object.values(PatientTabs));
	const [selectedRole, setSelectedRole] = useState({ value: UserRoles.NURSE, label: translate('virtualCareProvider') });
	const [currentTab, setCurrentTab] = useState(0);

	const featureFlagsCategories = props.featureFlagsCategories({
		selectedRole,
		isNewExperience,
		isUserExperienceHSEditable,
		anyPatientTabEnabled,
	});

	return (
		<Tabs
			className='configs-sub-tabs'
			isVertical={false}
			activeIndex={currentTab}
			onChange={index => {
				setSelectedRole({ value: UserRoles.NURSE, label: translate('virtualCareProvider') });
				setCurrentTab(index);
			}}>
			<TabList>
				{featureFlagsCategories.map(item => (
					<Tab key={item.settingCategory}>{item.title}</Tab>
				))}
			</TabList>
			<TabPanels>
				{featureFlagsCategories.map(category => (
					<TabPanel key={category.settingCategory}>
						{category.settingCategory !== SettingsCategory.AI_SETTINGS && (
							<FeatureFlags
								settingCategory={category.settingCategory}
								currentSector={props.currentSectorType}
								healthSystemId={props.selectedHealthSystem?.value}
								levelId={props.levelId}
								setError={props.setError}
								setSelectedHealthSystem={props.setSelectedHealthSystem}
								selectedHealthSystem={props.selectedHealthSystem}
								defaultConfigurations={category.defaultConfigurations}
								roleList={category.roleList}
								setSelectedRole={category.roleList ? setSelectedRole : null}
								selectedRole={category.roleList ? selectedRole : null}
								categoryOrder={category.categoryOrder}
							/>
						)}
						{category.settingCategory === SettingsCategory.AI_SETTINGS && (
							<AiFeatureFlags
								settingCategory={category.settingCategory}
								currentSector={props.currentSectorType}
								healthSystemId={props.selectedHealthSystem?.value}
								levelId={props.levelId}
								setError={props.setError}
								setSelectedHealthSystem={props.setSelectedHealthSystem}
								selectedHealthSystem={props.selectedHealthSystem}
								defaultConfigurations={category.defaultConfigurations}
								categoryOrder={category.categoryOrder}
							/>
						)}
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
};

export default FeatureFlagsCategories;
