import { getRoomSettings } from 'api/adminConfigurations.js';
import { forwardAiAlert } from 'api/alerts.js';
import { getCompanionDevicesByRoom } from 'api/devices.js';
import { getExternalDevices } from 'api/lifeSignals.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import { getUserPreferences } from 'api/users.js';
import { getActiveEncounter, setEncounterId } from 'api/whiteboard.js';
import { editWhiteboardControl, getWhiteboardControls } from 'api/whiteboardControls.js';
import { Alert, Grid, Loader } from 'components/index.js';
import InterventionsMonitoring from 'components/InterventionsMonitoring.jsx';
import PrivacyMode from 'components/Monitoring/PrivacyMode.jsx';
import TelemetryItems from 'components/Monitoring/TelemetryItems.jsx';
import VerbalRedirections from 'components/Monitoring/VerbalRedirections.jsx';
import VideoFeedActions from 'components/Monitoring/VideoFeedActions.jsx';
import {
	AiSetting,
	configurableAISettings,
	configurableMonitoringMenu,
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
} from 'constants/configurationEnums.js';
import {
	CareEventTypes,
	CompanionModeId,
	PatientAiSetting,
	TvSettingTypesId,
	WearableProviderIds,
	WearablesStatus,
} from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import AiSettings from 'containers/Monitoring/AiSettings.jsx';
import DisplayControl from 'containers/Monitoring/DisplayControl.jsx';
import PatientNotes from 'containers/Monitoring/PatientNotes.jsx';
import PatientNotesSimplified from 'containers/Monitoring/PatientNoteSimplified.jsx';
import PatientOutOfRoom from 'containers/Monitoring/PatientOutOfRoom.jsx';
import PatientPosition from 'containers/Monitoring/PatientPosition.jsx';
import PrecautionsBox from 'containers/Monitoring/PrecautionsBox.jsx';
import Whiteboard from 'containers/Monitoring/Whiteboard.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getAiSettingsConfigurations, getConfigurationValue, updateConfigsList } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';

const MonitoringTimeline = ({
	isDarkMode,
	conferenceInfo,
	feed,
	toggleOffPrivacy,
	togglePrivacyMode,
	isSimplifiedPatientForm,
	hierarchyNaming,
	onAiSettingClick,
	isNoteShowing,
	toggleFallPrevention,
	isFromAlertCenter = false,
	toggleNightVision,
	numberOfFeeds,
	setSnoozeType,
	stopVoiceOver,
	onAlertInfoClose,
	setVerbalRedirection,
	aiSettings,
	isDefaultOwner,
	patientId,
	conversationId,
	isBioBeatActive,
	toggleBioBeat,
	isLifeSignalsActive,
	toggleLifeSignals,
	setDeviceOwner,
	setIvBagPercentage,
	handleDiscontinue,
	isMindrayActive,
	toggleMindray,
}) => {
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [careEvents, setCareEvents] = useState([]);
	const [careEventsTotalCount, setCareEventsTotalCount] = useState(0);
	const [careEventsPageIndex, setCareEventsPageIndex] = useState(0);
	const userSession = useSelector(state => state.user.userSession);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const [patientOutOfRoomCareEvent, setPatientOutOfRoomCareEvent] = useState(null);
	const [patientReturnedCareEvent, setPatientReturnedCareEvent] = useState(null);
	const [lifeSignalsWearable, setLifeSignalsWearable] = useState(null);
	const [biobeatWearable, setBiobeatWearable] = useState(null);
	const [whiteboardDisplayControls, setWhiteboardDisplayControls] = useState([]);
	const [isWhiteboardAssigned, setIsWhiteboardAssigned] = useState(false);
	const [displayControlLoading, setDisplayControlLoading] = useState(false);
	const intl = useIntl();

	const excludedCareEvents = [
		'Patient Privacy'.toLowerCase(),
		'Set stat alarm'.toLowerCase(),
		'Patient Out of Room'.toLowerCase(),
		'Patient Returned to Room'.toLowerCase(),
	];

	const editablePanels = [
		{ description: 'nightVision', key: MonitoringSettings.NightVision },
		{ description: 'rails', key: AiSetting.RAILS },
		{ description: 'getOutOfBed', key: AiSetting.GET_OUT_OF_BED },
		{ description: 'fallDetection', key: AiSetting.FALL_DETECTION },
		{ description: 'patientMobility', key: AiSetting.PATIENT_MOBILITY },
		{ description: 'pressureInjury', key: AiSetting.PRESSURE_INJURY },
		{ description: 'aiPrivacyMode', key: AiSetting.AI_PRIVACY_MODE },
		{ description: 'handWashing', key: AiSetting.HAND_WASHING },
		{ description: 'ewsAiVitalSigns', key: AiSetting.EWS_AI_VITAL_SIGNS },
		{ description: 'ivBagFluidLevel', key: AiSetting.IV_BAG_FLUID_LEVEL },
		{ description: 'patientInfoBox', key: MonitoringSettings.PatientInfo },
		{ description: 'whiteboard', key: MonitoringSettings.Whiteboard },
		{ description: 'whiteboardControls', key: MonitoringSettings.DisplayControl },
		{ description: 'careEventsForSafetyCompanions', key: MonitoringSettings.CareEventsForSafetyCompanions },
		{ description: 'precautions', key: MonitoringSettings.Precautions },
		{ description: 'enablePrivacyMode', key: MonitoringSettings.Privacy },
	];

	const FeedTypes = {
		Monitoring: 0,
		AmbientMonitoring: 1,
	};

	const getEventByName = (arr, name) => {
		if (!arr) {
			return null;
		}
		return arr.find(item => item.name.toLowerCase() === name.toLowerCase());
	};

	const getCareEventsFiltered = arr => {
		if (!arr) {
			return [];
		}
		return arr.filter(item => !excludedCareEvents.includes(item.name.toLowerCase())) || [];
	};

	useEffect(() => {
		const getEncounterId = async userId => {
			if (!userId) {
				return;
			}
			const response = await getActiveEncounter(userId);
			if (!response.error) {
				setEncounterId(response?.encounter?.id);
			}
		};

		if (!isDefaultOwner) {
			getEncounterId(feed.deviceOwner?.healthcareUserId);
		}
	}, [isDefaultOwner, feed.deviceOwner?.healthcareUserId]);

	useEffect(() => {
		const onWhiteboardDisplayControlUpdated = data => {
			if (feed?.roomId !== data?.roomId) {
				return;
			}

			const newClonedData = _.cloneDeep(whiteboardDisplayControls);
			const itemIndex = whiteboardDisplayControls.findIndex(
				item => item?.settingTypeId === TvSettingTypesId[data?.settingType?.toUpperCase()]
			);

			if (itemIndex !== -1) {
				newClonedData[itemIndex] = { ...newClonedData[itemIndex], ...data };
				setWhiteboardDisplayControls(newClonedData);
			}
		};
		socket.on(SocketEvents.HelloDevice.TV_CONTROL_CHANGED, onWhiteboardDisplayControlUpdated);
		return () => {
			socket.off(SocketEvents.HelloDevice.TV_CONTROL_CHANGED, onWhiteboardDisplayControlUpdated);
		};
	}, [feed?.deviceId, socket, feed?.roomId, whiteboardDisplayControls]);

	useEffect(() => {
		const careEventSpecificTypes = {
			VERBAL_REDIRECTION: 'Verbal Redirection',
			PATIENT_OUT_OF_ROOM: 'Patient out of room',
			PATIENT_RETURNED_TO_ROOM: 'Patient Returned to Room',
		};
		const getEvents = async () => {
			const names = [
				careEventSpecificTypes.VERBAL_REDIRECTION,
				careEventSpecificTypes.PATIENT_OUT_OF_ROOM,
				careEventSpecificTypes.PATIENT_RETURNED_TO_ROOM,
			];

			const [allCareEventsResponse, specificCareEventsResponse] = await Promise.all([
				getCareEvents({
					pageSize: 10,
					pageIndex: careEventsPageIndex,
					healthSystemId: userSession.healthSystem.id,
					teamCareEventType: CareEventTypes.SafetyCompanions,
				}),
				getCareEvents({
					pageSize: 10,
					pageIndex: 0,
					healthSystemId: userSession.healthSystem.id,
					teamCareEventType: CareEventTypes.SafetyCompanions,
					names,
				}),
			]);

			if (allCareEventsResponse.error || specificCareEventsResponse.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const teamCareEvents = allCareEventsResponse?.teamCareEvents;
			const specificTeamCareEvents = specificCareEventsResponse?.teamCareEvents;

			setCareEvents(prevState => [...prevState, ...teamCareEvents]);
			setVerbalRedirection(getEventByName(specificTeamCareEvents, careEventSpecificTypes.VERBAL_REDIRECTION));
			setPatientOutOfRoomCareEvent(getEventByName(specificTeamCareEvents, careEventSpecificTypes.PATIENT_OUT_OF_ROOM));
			setPatientReturnedCareEvent(getEventByName(specificTeamCareEvents, careEventSpecificTypes.PATIENT_RETURNED_TO_ROOM));
			setCareEventsTotalCount(allCareEventsResponse.total);
		};
		getEvents();
	}, [intl, userSession.healthSystem.id, careEventsPageIndex]);

	useEffect(() => {
		setCareEvents([]);
		setCareEventsPageIndex(0);
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		if (!feed?.isDefaultOwner && !feed.deviceOwner?.healthcareUserId) {
			setDeviceOwner();
		}
	}, [feed?.deviceOwner?.healthcareUserId, feed?.isDefaultOwner]);

	useEffect(() => {
		const getWearable = async () => {
			const response = await getExternalDevices({ patientId: feed.deviceOwner?.userId, status: WearablesStatus.ACTIVE });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (response?.patientExternalIotDevices?.length > 0) {
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.LIFE_SIGNALS
					);
					if (found) {
						setLifeSignalsWearable(found);
					}
				}
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.BIO_BEAT
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.BIO_BEAT
					);
					if (found) {
						setBiobeatWearable(found);
					}
				}
			}
		};

		if (
			!isDefaultOwner &&
			feed.deviceOwner?.userId &&
			(getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) ||
				getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]))
		) {
			getWearable();
		}
	}, [feed.deviceId, feed.deviceOwner?.userId, isDefaultOwner, adminConfigurations]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			setIsLoading(true);
			const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(feed.roomId, SettingsCategory.MONITORING),
				getUserPreferences(UserSettingTypes.Monitoring, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, feed.roomId),
			]);
			const responseError = adminRoomSettings.error || myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				setIsLoading(false);
				return;
			}
			const response = updateConfigsList(adminRoomSettings, myRoomSettings);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			const resError = response.error || aiResponse.error;
			if (resError) {
				setError(resError);
			} else {
				setAdminConfigurations(response.configs);
				setAdminAiConfigurations(aiResponse.configs);
				const result = { ...patientNotes[feed.deviceId] };
				editablePanels.forEach(item => {
					result[item.description] = response.configs[item.key]?.value;
				});
				updatePatientData(result);
			}

			setIsLoading(false);
		};
		fetchRoomSettings();
	}, [feed.roomId]);

	useEffect(() => {
		const fetchWhiteboardControls = async () => {
			const response = await getWhiteboardControls({ roomId: feed?.roomId });
			if (response?.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			setWhiteboardDisplayControls(response);
			setIsWhiteboardAssigned(true);
		};

		const clearDisplayControlData = () => {
			setWhiteboardDisplayControls([]);
			setIsWhiteboardAssigned(false);
		};

		const checkCompanionDevice = async () => {
			if (!feed.roomId || !getConfigurationValue(adminConfigurations[MonitoringSettings.DisplayControl])) {
				return;
			}

			setDisplayControlLoading(true);
			const response = await getCompanionDevicesByRoom(feed.roomId);
			if (response?.error) {
				setDisplayControlLoading(false);
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}

			const whiteboardDevice = response?.result?.find(item => item.companionModeId === CompanionModeId.WHITEBOARD);
			if (whiteboardDevice) {
				await fetchWhiteboardControls();
			} else {
				clearDisplayControlData();
			}
			setDisplayControlLoading(false);
		};

		checkCompanionDevice();
	}, [feed.roomId, adminConfigurations]);

	const shouldShowPatientPosition = () =>
		aiSettingList
			.find(item => item.deviceId === feed.deviceId)
			?.settings.find(setting => setting.settingTypeId === PatientAiSetting.PRESSURE_INJURY)?.isEnabled &&
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PRESSURE_INJURY]);

	const toggleDisplayControl = async payload => {
		const response = await editWhiteboardControl({
			roomId: feed.roomId,
			payload: {
				tvSettingType: payload.settingType,
				tvSettingValue: payload.settingValue,
				tvIndex: payload.tvIndex,
			},
		});
		if (response?.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}

		const newClonedData = _.cloneDeep(whiteboardDisplayControls);
		const itemIndex = whiteboardDisplayControls.findIndex(item => item?.settingTypeId === payload?.settingTypeId);

		if (itemIndex !== -1) {
			newClonedData[itemIndex] = { ...newClonedData[itemIndex], ...payload };
			setWhiteboardDisplayControls(newClonedData);
		}
	};

	const updatePatientData = (items = []) => {
		if (items.length === 0) {
			return;
		}
		const newObj = {
			...patientNotes?.[feed.deviceId],
			...items,
		};
		dispatch(patientNotesActionCreators.setPatientDataSaved(newObj, feed?.deviceId));
	};

	const isAnyTelemetryEnabled = () =>
		(getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]) && lifeSignalsWearable) ||
		(getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) && biobeatWearable) ||
		getConfigurationValue(adminConfigurations[MonitoringSettings.SmartWatchVitalSigns]);

	// const showNotes = getConfigurationValue(adminConfigurations[MonitoringSettings.Notes]);
	const showForwardToNurses = getConfigurationValue(adminConfigurations[MonitoringSettings.ForwardToNurses]);

	const isAnyAiSettingEnabled = () =>
		Object.keys(adminAiSettingsConfigurations).some(
			key => +key !== AiSetting.DATA_COLLECTION && adminAiSettingsConfigurations[key].value
		) && !getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SILENT_MODE]);

	const shouldShowTelemetryTab = () => conferenceInfo && isAnyTelemetryEnabled() && feed?.feedType === FeedTypes.Monitoring;

	const sendAutomaticAlert = async (alertId, isAiAlert) => {
		const { hospital, department, floor, room } = hierarchyNaming;
		const dataToSend = {
			conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		if (isAiAlert) {
			const response = await forwardAiAlert(dataToSend);
			if (response.error) {
				setError(response.error.message);
			}
		}
	};

	const loadMoreCareEventOptions = () => {
		const hasReachedEnd = careEventsTotalCount - careEvents.length <= 0;
		if (!hasReachedEnd) {
			setCareEventsPageIndex(prevState => prevState + 1);
		}
	};

	const displayControlData = useMemo(() => {
		return whiteboardDisplayControls.find(item => item?.settingTypeId === TvSettingTypesId.DISPLAY);
	}, [whiteboardDisplayControls, feed?.roomId]);

	return (
		<div className='monitoring-timeline'>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					{patientNotes[feed.deviceId]?.whiteboard && !isDefaultOwner && !feed.deviceOwner?.isVirtualPatient && (
						<Whiteboard
							deviceOwnerId={feed.deviceOwner?.healthcareUserId}
							deviceId={feed.deviceId}
							patientName={feed.deviceOwner?.fullName || ''}
							patientAge={feed.deviceOwner?.age || ''}
						/>
					)}
					{!isFromAlertCenter && (
						<>
							{patientNotes[feed.deviceId]?.patientInfoBox && !isSimplifiedPatientForm && feed.deviceId && (
								<PatientNotes
									deviceId={feed.deviceId}
									roomId={feed.roomId}
									hierarchyNaming={hierarchyNaming}
									isDarkMode={isDarkMode}
									mrn={feed.deviceOwner?.mrn}
									patientName={feed.deviceOwner?.fullName || ''}
									patientAge={feed.deviceOwner?.age || ''}
									birthDate={feed.deviceOwner?.dateOfBirth || ''}
									genderIdentity={feed.deviceOwner?.genderIdentity || null}
									handleDiscontinue={handleDiscontinue}
									ehrCareTeam={feed.deviceOwner?.careTeam}
									healthSystemId={feed?.healthSystemId}
									deviceOwnerId={feed.deviceOwner?.healthcareUserId}
									conferenceId={conferenceInfo?.conferenceId}
									shouldShowDiscontinueReasons={getConfigurationValue(
										adminConfigurations[MonitoringSettings.DiscontinueMonitoring]
									)}
								/>
							)}
							{isSimplifiedPatientForm && (
								<PatientNotesSimplified deviceId={feed.deviceId} roomId={feed.roomId} hierarchyNaming={hierarchyNaming} />
							)}
							{patientNotes[feed.deviceId]?.precautions && !isDefaultOwner && (
								<PrecautionsBox
									deviceId={feed.deviceId}
									roomId={feed.roomId}
									deviceOwnerId={feed.deviceOwner?.healthcareUserId}
									aiSettings={aiSettings}
									toggleFallPrevention={toggleFallPrevention}
									isDefaultOwner={isDefaultOwner}
									deviceFamily={feed.deviceFamily}
									showPrecautions={patientNotes[feed.deviceId]?.precautions}
								/>
							)}
							{patientNotes[feed.deviceId]?.careEventsForSafetyCompanions && !isDefaultOwner && (
								<InterventionsMonitoring
									patientId={patientId}
									deviceId={feed.deviceId}
									careEvents={getCareEventsFiltered(careEvents)}
									feed={feed}
									numberOfFeeds={numberOfFeeds}
									setSnoozeType={setSnoozeType}
									stopVoiceOver={stopVoiceOver}
									showForwardToNurses={showForwardToNurses}
									onForwardToNurses={(alertId, isAiAlert) => sendAutomaticAlert(alertId, isAiAlert)}
									conferenceId={conferenceInfo?.conferenceId}
									onAlertInfoClose={onAlertInfoClose}
									loadMoreCareEventOptions={loadMoreCareEventOptions}
								/>
							)}

							{feed.feedType === FeedTypes.Monitoring && !isDefaultOwner && (
								<>
									{patientNotes[feed.deviceId]?.enablePrivacyMode && (
										<PrivacyMode
											toggleOffPrivacy={toggleOffPrivacy}
											feed={feed}
											patientId={patientId}
											togglePrivacyMode={togglePrivacyMode}
										/>
									)}
									{getConfigurationValue(adminConfigurations[MonitoringSettings.VerbalRedirection]) && (
										<VerbalRedirections
											feed={feed}
											conferenceInfo={conferenceInfo}
											preferredLanguage={patientNotes[feed.deviceId]?.preferredLanguage || null}
											healthSystemId={feed?.healthSystemId}
										/>
									)}
									{getConfigurationValue(adminConfigurations[MonitoringSettings.PatientOutOfRoom]) && (
										<PatientOutOfRoom
											feed={feed}
											patientId={patientId}
											patientOutOfRoomCareEvent={patientOutOfRoomCareEvent}
											patientReturnedCareEvent={patientReturnedCareEvent}
											conferenceId={conferenceInfo?.conferenceId}
										/>
									)}
								</>
							)}
						</>
					)}
					{patientNotes[feed.deviceId]?.nightVision && feed.feedType === FeedTypes.Monitoring && (
						<VideoFeedActions feed={feed} toggleNightVision={toggleNightVision} />
					)}

					{getConfigurationValue(adminConfigurations[MonitoringSettings.DisplayControl]) &&
						feed.feedType === FeedTypes.Monitoring && (
							<DisplayControl
								data={displayControlData}
								toggleDisplayControl={toggleDisplayControl}
								isDeviceAssigned={isWhiteboardAssigned}
								isLoading={displayControlLoading}
							/>
						)}

					{isAnyAiSettingEnabled() && !isDefaultOwner && (
						<AiSettings
							feed={feed}
							patientId={patientId}
							isDarkMode={isDarkMode}
							onAiSettingClick={onAiSettingClick}
							isNoteShowing={isNoteShowing}
							toggleFallPrevention={toggleFallPrevention}
							stopVoiceOver={stopVoiceOver}
							onAlertInfoClose={onAlertInfoClose}
							adminAiSettingsConfigurations={adminAiSettingsConfigurations}
							setIvBagPercentage={setIvBagPercentage}
							aiSettings={aiSettings}
							conferenceId={conferenceInfo?.conferenceId}
						/>
					)}

					{shouldShowPatientPosition() && (
						<PatientPosition deviceId={feed.deviceId} isDarkMode={isDarkMode} patientId={patientId} />
					)}

					{shouldShowTelemetryTab() && (
						<TelemetryItems
							deviceId={feed.deviceId}
							conferenceId={conferenceInfo?.conferenceId}
							participantId={feed.participantId}
							patientUserId={feed.deviceOwner?.userId}
							isBioBeatActive={isBioBeatActive}
							toggleBioBeat={toggleBioBeat}
							isLifeSignalsActive={isLifeSignalsActive}
							isMindrayActive={isMindrayActive}
							toggleLifeSignals={toggleLifeSignals}
							toggleMindray={toggleMindray}
							healthDataConfigurableMonitoring={{
								[MonitoringSettings.SmartWatchVitalSigns]: getConfigurationValue(
									adminConfigurations[MonitoringSettings.SmartWatchVitalSigns]
								),
								[MonitoringSettings.BiobeatVitalSigns]: getConfigurationValue(
									adminConfigurations[MonitoringSettings.BiobeatVitalSigns]
								),
								[MonitoringSettings.LifeSignals]: getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]),
								[MonitoringSettings.Mindray]: true,
							}}
							lifeSignalsWearable={lifeSignalsWearable}
							biobeatWearable={biobeatWearable}
							isDarkMode={isDarkMode}
						/>
					)}
				</>
			)}

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default MonitoringTimeline;
