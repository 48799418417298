import { LiveCaptionsIcon, MicIcon, NightVisionIconOn } from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { CallTypes, EventCategory, UserRoles, UserTypes } from 'constants/enums.js';
import {
	AiIcon,
	AlertIcon,
	AmbientMonitoring,
	GearIconAlt,
	PatientOutOfRoomAlt,
	PatientPrivacy,
	PrecautionsIcon,
	ProfileIcon,
	VideoMonitoring,
	VoiceIcon,
} from 'icons/Monitoring/index.js';
import { getTimezoneAbbreviation } from 'infrastructure/helpers/dateHelper.js';

export const DashboardContent = [
	{ id: 'conferenceId', label: 'conferenceId', checked: false },
	{ id: 'initiatorDevice', label: 'initiatorDevice', checked: false },
	{ id: 'provider', label: 'provider', checked: false },
	{ id: 'room', label: 'room', checked: false },
	{ id: 'patient', label: 'patient', checked: false },
	{ id: 'startTime', label: 'callStart', checked: false },
	{ id: 'endedTime', label: 'callEnd', checked: false },
	{ id: 'duration', label: 'duration', checked: false },
	{ id: 'durationInSeconds', label: 'durationInSeconds', checked: false },
	{ id: 'calltype', label: 'callType', checked: false },
	{ id: 'origin', label: 'callOrigin', checked: false },
	{ id: 'careEventTypeName', label: 'careEventType', checked: false },
	{ id: 'patientmrn', label: 'patientMRN', checked: false },
	{ id: 'usedaudio', label: 'usedAudio', checked: false },
	{ id: 'usedvideo', label: 'usedVideo', checked: false },
	{ id: 'usedscreen', label: 'usedScreen', checked: false },
	{ id: 'status', label: 'callStatus', checked: false },
	{ id: 'invitedParticipants', label: 'invitedParticipants', checked: false },
	{ id: 'statAlarm', label: 'statAlarmInCSV', cheked: false },
	{ id: 'interpretationServices', label: 'interpretationServices', checked: false },
];

export const sessionByHours = intl => [
	{
		label: intl.formatMessage({ id: 'videoMonitoring' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: LightTheme.colors.blueFour,
		borderWidth: 0,
		backgroundColor: LightTheme.colors.blueFour08,
		pointRadius: 0,
		pointBackgroundColor: LightTheme.colors.blueFour,
		fill: false,
		callTypes: [CallTypes.MONITORING],
		total: 0,
		order: 1,
	},
	{
		label: intl.formatMessage({ id: 'ambientMonitoring' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: LightTheme.colors.purpleTwo,
		borderWidth: 0,
		backgroundColor: LightTheme.colors.purpleTwo08,
		pointRadius: 0,
		pointBackgroundColor: LightTheme.colors.purpleTwo,
		fill: false,
		callTypes: [CallTypes.AMBIENT],
		total: 0,
		order: 2,
	},
	{
		label: intl.formatMessage({ id: 'talkToPatient' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: LightTheme.colors.greenTwo,
		borderWidth: 0,
		backgroundColor: LightTheme.colors.greenTwo08,
		pointRadius: 0,
		pointBackgroundColor: LightTheme.colors.greenTwo,
		fill: false,
		callTypes: [CallTypes.AUDIO, CallTypes.VIDEO],
		total: 0,
		order: 3,
	},
	{
		label: intl.formatMessage({ id: 'viewPatient' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#7892C3',
		borderWidth: 0,
		backgroundColor: '#7892C3CC',
		pointRadius: 0,
		pointBackgroundColor: '#7892C3',
		fill: false,
		callTypes: [CallTypes.SECURITY_CAM],
		total: 0,
		order: 4,
	},
];

export const admissionsDischarges = intl => [
	{
		label: intl.formatMessage({ id: 'admissions' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#5390FF',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#5390FF',
		fill: false,
		lineType: AdmittancesTypes.ADMIT,
	},
	{ label: '', data: [], borderColor: 'transparent', backgroundColor: 'transparent' },
	{
		label: intl.formatMessage({ id: 'discharges' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#F15E5E',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#F15E5E',
		fill: false,
		lineType: AdmittancesTypes.DISCHARGE,
	},
];

export const providersRoles = [
	{ id: UserTypes.DOCTOR, name: UserRoles.DOCTOR },
	{ id: UserTypes.NURSE, name: UserRoles.NURSE },
	{ id: UserTypes.VIRTUAL_SITTER, name: UserRoles.VIRTUAL_SITTER },
	{ id: UserTypes.ROOM_SIGN, name: UserRoles.ROOM_SIGN },
];

export const BarChartColors = {
	NURSE: 'var(--green-7)',
	DOCTOR: 'var(--green-2)',
	VIRTUAL_SITTER: 'var(--green-6)',
	VISITOR: 'var(--green-5)',
	PATIENT: 'var(--green-4)',
};

export const initialInitiatorRoundingData = [
	{ id: 1, roleId: UserTypes.NURSE, text: 'virtualCareProvider', count: 0, percentage: 0, color: BarChartColors.NURSE },
	{ id: 2, roleId: UserTypes.DOCTOR, text: 'doctor', count: 0, percentage: 0, color: BarChartColors.DOCTOR },
	{
		id: 3,
		roleId: UserTypes.VIRTUAL_SITTER,
		text: 'virtualSitter',
		count: 0,
		percentage: 0,
		color: BarChartColors.VIRTUAL_SITTER,
	},
	{
		id: 4,
		roleId: UserTypes.VISITOR,
		text: 'visitor',
		count: 0,
		percentage: 0,
		color: BarChartColors.VISITOR,
	},
	{
		id: 5,
		roleId: UserTypes.PATIENT,
		text: 'patient',
		count: 0,
		percentage: 0,
		color: BarChartColors.PATIENT,
	},
];

export const byHourChartLabels = [
	'12 AM',
	'1 AM',
	'2 AM',
	'3 AM',
	'4 AM',
	'5 AM',
	'6 AM',
	'7 AM',
	'8 AM',
	'9 AM',
	'10 AM',
	'11 AM',
	'12 PM',
	'1 PM',
	'2 PM',
	'3 PM',
	'4 PM',
	'5 PM',
	'6 PM',
	'7 PM',
	'8 PM',
	'9 PM',
	'10 PM',
	'11 PM',
];

export const byHourChartOptions = (xLabelScales, translator) => ({
	scales: {
		yAxes: [
			{
				stacked: true,
			},
		],
		xAxes: [
			{
				stacked: true,
				gridLines: {
					display: false,
				},
				ticks: {
					labelOffset: -30,
				},
			},
		],
	},
	legend: {
		display: false,
	},
	tooltips: {
		callbacks: {
			title: (tooltipItem, data) => {
				const { index, label } = tooltipItem[0];
				const toIndex = (index + 1) % data.labels.length;

				return `${label} to ${data.labels[toIndex]}`;
			},
			footer: (tooltipItem, data) => {
				const { index } = tooltipItem[0];
				const totalSessions = data.datasets.reduce(
					(acc, curr) => (Object.values(curr._meta)[0]?.hidden ? acc : (acc += curr.data[index])),
					0
				);
				return `${totalSessions} ${translator('totalSessions').toLowerCase()}`;
			},
		},
		titleMarginBottom: 10,
		footerMarginTop: 15,
	},
});

export const aiAlertsChartOptions = label => ({
	cutoutPercentage: 75,
	maintainAspectRatio: false,
	responsive: true,
	legend: false,
	elements: {
		center: {
			enable: true,
			labelText: label,
			fontSize: '48px',
		},
	},
});

export const AdmittancesTypes = {
	ADMIT: 1,
	DISCHARGE: 2,
};

export const buildDataPerHours = list => {
	const data = byHourChartLabels.map(label => {
		let hour = parseInt(label.split(' ')[0], 10);
		if (hour === 12 && label.includes('AM')) {
			hour = 0;
		} else if (hour !== 12 && label.includes('PM')) {
			hour += 12;
		}

		const countData = list.reduce((accumulator, item) => {
			let total = accumulator;
			if (item.hour === hour) {
				total += item.count;
			}

			return total;
		}, 0);

		return countData;
	});

	return data;
};

export const DevicesStatus = {
	OFFLINE: 'Offline',
	ONLINE: 'Online',
};

export const initialDevicesStatusData = {
	[DevicesStatus.OFFLINE]: {
		label: 'Offline',
		value: 0,
		percentage: 0,
		stroke: '#718093',
	},
	[DevicesStatus.ONLINE]: {
		label: 'Online',
		value: 0,
		percentage: 0,
		stroke: '#33C27F',
	},
};

export const RoundingTypes = {
	TALK_TO_PATIENT: {
		type: 0,
		value: 'talk-to-patient',
	},
	VIEW_PATIENT: {
		type: 1,
		value: 'view-patient',
	},
};

export const RoundingOrigins = {
	OUTGOING: {
		type: 0,
		color: '#6BC6B5',
		description: 'outgoingSessionsDescription',
	},
	INCOMING: {
		type: 1,
		color: '#8EB66F',
		description: 'incomingSessionsDescription',
	},
	VIEW_PATIENT: {
		type: 2,
		color: '#7892C3',
		description: 'viewPatientSessionsDescription',
	},
};

export const EventCategoryIcon = {
	[EventCategory.AI_ALERT]: <AlertIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.STAT_ALARM]: <AmbientMonitoring color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.PATIENT_INFO_BOX_UPDATE]: <ProfileIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.VERBAL_REDIRECTION]: <VoiceIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.MICROPHONE]: <MicIcon color={LightTheme.colors.grayFive} strokeWidth={1} />,
	[EventCategory.PATIENT_OUT_OF_ROOM]: <PatientOutOfRoomAlt color={LightTheme.colors.grayFive} width={24} height={24} />,
	[EventCategory.PRIVACY]: <PatientPrivacy color={LightTheme.colors.grayFive} strokeWidth={1} width={24} height={24} />,
	[EventCategory.NIGHT_VISION]: <NightVisionIconOn color={LightTheme.colors.grayFive} strokeWidth={1} />,
	[EventCategory.PRECAUTIONS]: <PrecautionsIcon color={LightTheme.colors.grayFive} strokeWidth={0.7} width={24} height={24} />,
	[EventCategory.LIVE_CAPTION]: <LiveCaptionsIcon color={LightTheme.colors.grayFive} strokeWidth={1.25} />,
	[EventCategory.MANUAL_INTERVENTION_AND_ALERT]: <GearIconAlt color={LightTheme.colors.grayFive} />,
	[EventCategory.AI_ALERT_INTERVENTION]: <AiIcon color={LightTheme.colors.grayFive} />,
	[EventCategory.AMBIENT_VIDEO_SWITCH]: <VideoMonitoring color={LightTheme.colors.grayFive} width={24} height={24} />,
};

export const tabEnums = { OVERVIEW: 0, CARE_TEAM_REPORT: 1, PATIENT_REPORT: 2, HAND_HYGIENE: 3 };

export const DetailedReportType = { SESSIONS: 0, INTERVENTIONS: 1, AI: 2 };

export const DefaultTimezone = { label: 'Coordinated Universal Time', zone: 'UTC' };

export const formatDateHeader = (intl, intlId, timezone) =>
	`${intl.formatMessage({ id: intlId })} (${getTimezoneAbbreviation(timezone)})`;
