import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import EcareManager from 'containers/Configurations/EcareManager.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRoles } from 'constants/enums.js';
import { groupTypes } from 'constants/integrationEnums.js';

const IntegrationWrapper = () => {
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [currentTab, setCurrentTab] = useState(0);
	const shouldShowTranslationServicesTab = () =>
		getUserRole() !== UserRoles.SUPER_USER &&
		getSomeConfigurationsValues(companyConfigurations, [
			IntegrationTypesSettings.GLOBO,
			IntegrationTypesSettings.AMN,
			IntegrationTypesSettings.LANGUAGE_LINE,
		]);

	return (
		<Tabs className='configs-sub-tabs' activeIndex={currentTab} onChange={setCurrentTab}>
			<TabList>
				<Tab>{translate(groupTypes.ECM.title)}</Tab>
				<Tab>{translate(groupTypes.SMART_FHIR.title)}</Tab>
				<Tab>{translate(groupTypes.NURSE_CALLING.title)}</Tab>
				<Tab>{translate(groupTypes.PATIENT_INFOTAINMENT.title)}</Tab>
				{shouldShowTranslationServicesTab() && <Tab>{translate(groupTypes.TRANSLATION_SERVICES.title)}</Tab>}
			</TabList>
			<TabPanels>
				<TabPanel>
					<EcareManager group={groupTypes.ECM} />
				</TabPanel>
				<TabPanel>
					<EcareManager group={groupTypes.SMART_FHIR} />
				</TabPanel>
				<TabPanel>
					<EcareManager group={groupTypes.NURSE_CALLING} />
				</TabPanel>
				<TabPanel>
					<EcareManager group={groupTypes.PATIENT_INFOTAINMENT} />
				</TabPanel>
				{shouldShowTranslationServicesTab() && (
					<TabPanel>
						<EcareManager group={groupTypes.TRANSLATION_SERVICES} />
					</TabPanel>
				)}
			</TabPanels>
		</Tabs>
	);
};

export default IntegrationWrapper;
