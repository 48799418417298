import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { AlertTypes, AudioRoutingOption, CameraUseOption } from 'constants/enums.js';
import Select from 'components/Select.jsx';
import Form from 'components/Form.jsx';
import { CompanySettings, ConfigurableCallSettings, ConfigurableRoomSignCallSettings } from 'constants/configurationEnums.js';
import { updateOrganizationSettings } from 'api/companies.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import ConfigSettingInput from 'components/ConfigSettingInput.jsx';
import CallSettings from 'components/CallSettings.jsx';
import ConfigSettingRadioButtonInput from 'components/ConfigSettingRadioButtonInput.jsx';

const CreateCompanyCallSettings = props => {
	const intl = useIntl();
	const [answer, setAnswer] = useState({
		viewPatient: props.dataToSubmit.viewPatient.value,
		providerMic: props.dataToSubmit.providerMic.value,
		patientMicrophone: props.dataToSubmit.patientMicrophone.value,
		providerCamera: props.dataToSubmit.providerCamera.value,
		patientCamera: props.dataToSubmit.patientCamera.value,
		rsProviderMic: props.dataToSubmit.rsProviderMic.value,
		rsPatientMic: props.dataToSubmit.rsPatientMic.value,
		rsProviderCamera: props.dataToSubmit.rsProviderCamera.value,
		rsPatientCamera: props.dataToSubmit.rsPatientCamera.value,
		routingTalkToPatient: props.dataToSubmit.routingTalkToPatient.value,
		routingMonitoring: props.dataToSubmit.routingMonitoring.value,
		routingViewPatient: props.dataToSubmit.routingViewPatient.value,
		cameraUse: props.dataToSubmit.cameraUse,
	});
	const [locked, setLocked] = useState({
		routingTalkToPatient: props.dataToSubmit.routingTalkToPatient.isLocked,
		routingMonitoring: props.dataToSubmit.routingMonitoring.isLocked,
		routingViewPatient: props.dataToSubmit.routingViewPatient.isLocked,
	});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [alertType, setAlertType] = useState(null);
	const [devices, setDevices] = useState(props.dataToSubmit.devices);
	const [isCustomAudioConfigurations, setIsCustomAudioConfigurations] = useState(props.dataToSubmit.isCustomConfigurations);

	const audioRoutesOptions = [
		{
			id: 0,
			content: intl.formatMessage({ id: 'alwaysFromHello' }),
			value: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		{
			id: 1,
			content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
			value: AudioRoutingOption.TV_PATIENT_NURSE,
		},
		{
			id: 2,
			content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
			value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
		},
		{
			id: 3,
			content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
			value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
		},
	];

	const cameraUseOptions = [
		{ id: 0, content: 'singleCameraUse', variant: CameraUseOption.SINGLE_CAMERA_USE },
		{ id: 1, content: 'multiCameraUse', variant: CameraUseOption.MULTI_CAMERA_USE },
	];
	const submitParams = {
		viewPatient: {
			value: answer.viewPatient,
			isLocked: false,
		},
		providerMic: {
			value: answer.providerMic,
			isLocked: false,
		},
		providerCamera: {
			value: answer.providerCamera,
			isLocked: false,
		},
		patientMicrophone: {
			value: answer.patientMicrophone,
			isLocked: false,
		},
		patientCamera: {
			value: answer.patientCamera,
			isLocked: false,
		},
		rsProviderMic: {
			value: answer.rsProviderMic,
			isLocked: false,
		},
		rsProviderCamera: {
			value: answer.rsProviderCamera,
			isLocked: false,
		},
		rsPatientMic: {
			value: answer.rsPatientMic,
			isLocked: false,
		},
		rsPatientCamera: {
			value: answer.rsPatientCamera,
			isLocked: false,
		},
		routingTalkToPatient: {
			value: answer.routingTalkToPatient,
			isLocked: locked.routingTalkToPatient,
		},
		routingMonitoring: {
			value: answer.routingMonitoring,
			isLocked: locked.routingMonitoring,
		},
		routingViewPatient: {
			value: answer.routingViewPatient,
			isLocked: locked.routingViewPatient,
		},
		cameraUse: {
			value: true,
			variant: answer.cameraUse.variant,
		},
		devices,
		isCustomConfigurations: isCustomAudioConfigurations,
	};

	const getAudioRoutingArr = () => {
		let audioRoutingArr = [];

		if (isCustomAudioConfigurations) {
			const devicesAudioRouting = devices.map(item => [
				{
					settingTypeId: CompanySettings.TALK_TO_PATIENT_ROUTE,
					value: item.value.talkToPatient,
					deviceTypeId: item.enum,
					isLocked: item.isLocked,
				},
				{
					settingTypeId: CompanySettings.MONITORING_ROUTE,
					value: item.value.monitoring,
					deviceTypeId: item.enum,
					isLocked: item.isLocked,
				},
				{
					settingTypeId: CompanySettings.VIEW_PATIENT_ROUTE,
					value: item.value.viewPatient,
					deviceTypeId: item.enum,
					isLocked: item.isLocked,
				},
			]);
			audioRoutingArr = devicesAudioRouting.flat();
		} else {
			audioRoutingArr = [
				{
					settingTypeId: CompanySettings.TALK_TO_PATIENT_ROUTE,
					value: answer.routingTalkToPatient.toString(),
					isLocked: locked.routingTalkToPatient,
				},
				{
					settingTypeId: CompanySettings.MONITORING_ROUTE,
					value: answer.routingMonitoring.toString(),
					isLocked: locked.routingMonitoring,
				},
				{
					settingTypeId: CompanySettings.VIEW_PATIENT_ROUTE,
					value: answer.routingViewPatient.toString(),
					isLocked: locked.routingViewPatient,
				},
			];
		}
		return audioRoutingArr;
	};

	const onSubmit = async e => {
		e.preventDefault();
		if (!props.selectedCompany) {
			props.setDataToSubmit(submitParams);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);
			const settingsParams = {
				companySettings: [
					{
						settingTypeId: CompanySettings.CAMERA_USE,
						value: 'true',
						variant: answer.cameraUse.variant,
					},
					...getAudioRoutingArr(),
				],
				companyId: props.selectedCompany.id,
			};
			const response = await updateOrganizationSettings(settingsParams);

			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(submitParams);
			} else {
				setAlertType(AlertTypes.DANGER);
			}

			setIsSaveLoading(false);
		}
	};

	const getDeviceLockedState = item => {
		const devicesList = _.cloneDeep(devices);
		const found = devicesList.find(elem => elem.enum === item.enum);
		found.isLocked = !found.isLocked;
		return devicesList;
	};

	const getDeviceConfigurationValue = (item, type, event) => {
		const devicesList = _.cloneDeep(devices);
		const found = devicesList.find(elem => elem.enum === item.enum);
		found.value[type] = event.target.value;
		return devicesList;
	};

	const setDeviceLockedState = item => {
		props.setDataToSubmit({
			...submitParams,
			devices: getDeviceLockedState(item),
		});
		setDevices(prevState => {
			const devicesList = _.cloneDeep(prevState);
			const found = devicesList.find(elem => elem.enum === item.enum);
			found.isLocked = !found.isLocked;
			return devicesList;
		});
	};

	const setDeviceConfigValue = (event, item, type) => {
		props.setDataToSubmit({
			...submitParams,
			devices: getDeviceConfigurationValue(item, type, event),
		});
		event.persist();
		setDevices(prevState => {
			const devicesList = _.cloneDeep(prevState);
			const found = devicesList.find(elem => elem.enum === item.enum);
			found.value[type] = event.target.value;
			return devicesList;
		});
	};

	const handleChangeConfig = (config, isLock) => {
		props.setDataToSubmit({
			...submitParams,
			[config.name]: {
				value: isLock ? answer[config.name] : !answer[config.name],
				isLocked: isLock ? !locked[config.name] : locked[config.name],
			},
		});
		if (isLock) {
			setLocked(prevState => ({ ...prevState, [config.name]: !prevState[config.name] }));
		} else {
			setAnswer(prevState => ({ ...prevState, [config.name]: !prevState[config.name] }));
		}
	};

	const handleSelectValue = (event, config) => {
		props.setDataToSubmit({
			...submitParams,
			[config.name]: {
				value: event.target.value,
				isLocked: false,
			},
		});
		event.persist();
		setAnswer(prevState => ({ ...prevState, [config.name]: event.target.value }));
	};

	const handleCustomConfigChange = param => {
		props.setDataToSubmit({
			...submitParams,
			[param]: !isCustomAudioConfigurations,
		});
		setIsCustomAudioConfigurations(prevState => !prevState);
	};

	const handleCameraUseChange = (event, config) => {
		props.setDataToSubmit({
			...submitParams,
			cameraUse: { variant: event.target.value, value: 'true' },
		});
		setAnswer(prevState => ({ ...prevState, [config.name]: { variant: event.target.value } }));
	};

	return (
		<div className='create-company-wrapper create-company-settings create-company-call-settings configurations-call-settings-admin'>
			<Form className='call-settings-wallpapers'>
				{!props.selectedCompany && (
					<>
						<CallSettings
							handleChangeConfig={handleChangeConfig}
							submitParams={submitParams}
							key='callSettings'
							defaultConfigs={ConfigurableCallSettings}
							title={translate('providerAndPatientCallSettings')}
							callConfigsDescription={translate('talkToPatientSettingsDesc')}
						/>
						<CallSettings
							handleChangeConfig={handleChangeConfig}
							submitParams={submitParams}
							key='roomSignCallSettings'
							defaultConfigs={ConfigurableRoomSignCallSettings}
							title={translate('roomSignCallSettings')}
							callConfigsDescription={translate('callSettingsForRoomSignDesc')}
						/>
					</>
				)}
				<h3>{translate('audioRoutingOptions')}</h3>
				<p>{translate(isCustomAudioConfigurations ? 'audioRoutingDeviceType' : 'selectAudioRoutingOptions')}</p>
				<ConfigSettingInput
					isToggle={true}
					config={{
						title: 'customConfigurations',
						name: 'customConfigurations',
						key: null,
						value: isCustomAudioConfigurations,
						locked: null,
					}}
					statusText={translate('basedOnDeviceType')}
					handleChangeConfig={handleCustomConfigChange}
					handleSelectValue={handleSelectValue}
				/>

				{!isCustomAudioConfigurations && (
					<>
						<ConfigSettingInput
							isToggle={false}
							config={{
								title: 'talkToPatient',
								name: 'routingTalkToPatient',
								key: CompanySettings.TALK_TO_PATIENT_ROUTE,
								value: answer.routingTalkToPatient,
								locked: locked.routingTalkToPatient,
								options: audioRoutesOptions,
							}}
							statusText={translate('selectOneOfRoutingOptions')}
							handleChangeConfig={handleChangeConfig}
							handleSelectValue={handleSelectValue}
						/>
						<ConfigSettingInput
							isToggle={false}
							config={{
								title: 'monitoring',
								name: 'routingMonitoring',
								key: CompanySettings.MONITORING_ROUTE,
								value: answer.routingMonitoring,
								locked: locked.routingMonitoring,
								options: audioRoutesOptions.filter((_elem, index) => index === 0),
							}}
							statusText={translate('selectOneOfRoutingOptions')}
							handleChangeConfig={handleChangeConfig}
							handleSelectValue={handleSelectValue}
						/>
						<ConfigSettingInput
							isToggle={false}
							config={{
								title: 'viewPatient',
								name: 'routingViewPatient',
								key: CompanySettings.VIEW_PATIENT_ROUTE,
								value: answer.routingViewPatient,
								locked: locked.routingViewPatient,
								options: audioRoutesOptions.filter((_elem, index) => index === 0),
							}}
							statusText={translate('selectOneOfRoutingOptions')}
							handleChangeConfig={handleChangeConfig}
							handleSelectValue={handleSelectValue}
						/>
					</>
				)}

				{isCustomAudioConfigurations && (
					<div className='call-settings-custom-configurations'>
						<div className='flex custom-config-devices'>
							<span className='flex-1 flex'>
								<span className='material-icons-outlined'>devices_other</span>
								{translate('devices')}
							</span>
							<span className='flex-1 flex'>
								<img src={`${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`} alt='icon' />
								{translate('talkToPatient')}
							</span>
							<span className='flex-1 flex'>
								<img src={`${healthCareCdnUrl}admin/super-admin/monitoring.svg`} alt='icon' />
								{translate('monitoring')}
							</span>
							<span className='flex-1 flex'>
								<img src={`${healthCareCdnUrl}admin/super-admin/view-patient.svg`} alt='icon' />
								{translate('viewPatient')}
							</span>
						</div>
						{devices.map(item => (
							<div className='flex devices-routing' key={item.enum}>
								<div>
									<img src={`${healthCareCdnUrl}admin/super-admin/${item.image}`} alt='icon' />
								</div>
								<section>
									<p>
										{item.name}
										<br />
										<Button
											variant='light-green'
											className={item.isLocked ? 'locked' : ''}
											onClick={() => {
												setDeviceLockedState(item);
											}}
											imgIcon={`${healthCareCdnUrl}admin/super-admin/${item.isLocked ? 'lock' : 'unlock'}.svg`}
											text={translate(item.isLocked ? 'locked' : 'unlocked')}
										/>
									</p>
								</section>
								<Select
									name='talkToPatient'
									items={item.options}
									value={item.value.talkToPatient}
									valueField='value'
									textField='content'
									onSelect={event => {
										setDeviceConfigValue(event, item, 'talkToPatient');
									}}
								/>
								<Select
									name='monitoring'
									items={item.options.filter((_elem, index) => index === 0)}
									value={item.value.monitoring}
									valueField='value'
									textField='content'
									onSelect={event => {
										setDeviceConfigValue(event, item, 'monitoring');
									}}
								/>
								<Select
									name='viewPatient'
									items={item.options.filter((_elem, index) => index === 0)}
									value={item.value.viewPatient}
									valueField='value'
									textField='content'
									onSelect={event => {
										setDeviceConfigValue(event, item, 'viewPatient');
									}}
								/>
							</div>
						))}
					</div>
				)}
				<h3>{translate('cameraSettings')}</h3>
				<ConfigSettingRadioButtonInput
					config={{
						title: 'cameraUse',
						name: 'cameraUse',
						key: CompanySettings.CAMERA_USE,
					}}
					cameraUseValue={submitParams.cameraUse}
					statusText={translate('selectOneOfCameraSettings')}
					handleCameraUseChange={handleCameraUseChange}
					cameraUseOptions={cameraUseOptions}
				/>

				<div className='create-hs__add'>
					{!props.selectedCompany && (
						<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} disabled={!props.isValid} />
					)}
					<Button
						type='submit'
						text={translate(props.selectedCompany ? 'save' : 'nextStep')}
						onClick={onSubmit}
						isLoading={isSaveLoading}
					/>
				</div>
			</Form>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
		</div>
	);
};

export default CreateCompanyCallSettings;
