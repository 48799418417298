import Select from 'react-select';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { Form, Modal } from 'components/index.js';
import * as Yup from 'yup';
import { CompanyRoles } from 'constants/enums.js';

const AddMemberRole = props => {
	const intl = useIntl();
	const healthSystemsArr = props.healthSystems.map(hs => ({ value: hs.id, label: hs.value }));
	const maxRolesLengthWithoutAdmin = 5;

	const allRoles = [
		{ label: intl.formatMessage({ id: 'nurse' }), value: CompanyRoles.VIRTUAL_CARE_PROVIDER },
		{ label: intl.formatMessage({ id: 'digitalClinicianOnList' }), value: CompanyRoles.DIGITAL_CLINICIAN },
		{ label: intl.formatMessage({ id: 'doctor' }), value: CompanyRoles.DOCTOR },
		{ label: intl.formatMessage({ id: 'adminOnList' }), value: CompanyRoles.ADMIN },
		{ label: intl.formatMessage({ id: 'virtualSitter' }), value: CompanyRoles.VIRTUAL_SITTER },
		{ label: intl.formatMessage({ id: 'superUserOnList' }), value: CompanyRoles.SUPER_USER },
	];

	const showHealthSystem = selectedRole => {
		const rolesExcludingAdmin = getRolesToBeAdded().filter(role => role.value !== CompanyRoles.ADMIN);
		const allNonAdminRolesFilled = rolesExcludingAdmin.length === maxRolesLengthWithoutAdmin;
		const isSuperUserWithoutHealthSystems =
			selectedRole?.value === CompanyRoles.SUPER_USER && props.currentMemberObj.healthSystems?.length === 0;
		return allNonAdminRolesFilled || isSuperUserWithoutHealthSystems;
	};

	const getRolesToBeAdded = () =>
		props.currentMemberObj
			? allRoles.filter(role => !props.currentMemberObj.roles.map(item => item.id).includes(role.value))
			: [];

	const getValidationSchema = () =>
		Yup.object().shape({
			selectedRole: Yup.object()
				.nullable()
				.required(intl.formatMessage({ id: 'roleIsRequired' }))
				.test('selectedRole', intl.formatMessage({ id: 'superUserRoleValidation' }), role =>
					role?.value === CompanyRoles.SUPER_USER ? props.currentMemberObj?.healthSystems?.length <= 1 : true
				),
			healthSystem: Yup.mixed().when('selectedRole', (selectedRole, schema) =>
				showHealthSystem(selectedRole)
					? schema.nullable().required(intl.formatMessage({ id: 'healthSystemRequired' }))
					: schema.nullable()
			),
		});

	return (
		<Formik
			initialValues={{
				healthSystem: null,
				selectedRole: null,
			}}
			onSubmit={props.onAddMemberRole}
			validationSchema={getValidationSchema()}
			enableReinitialize={true}
			validateOnBlur={true}>
			{formikProps => {
				const { values, errors, isValid, setFieldValue, handleSubmit, resetForm } = formikProps;
				return (
					<Modal
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
						display={props.isAddRoleModalOpen}
						position='right'
						onModalSubmit={handleSubmit}
						primaryButtonLabel={translate('add')}
						onModalClose={() => {
							resetForm();
							props.onModalClose();
						}}
						shouldSubmitOnEnter={false}
						isSubmitDisabled={!isValid || !values.selectedRole || (showHealthSystem(values.selectedRole) && !values.healthSystem)}
						primaryButtonLoading={props.isBtnLoading}>
						<Form className='add-a-role'>
							<h3>{translate('addARole')}</h3>
							<p>{translate('pleaseSelectRole')}</p>
							<Select
								options={getRolesToBeAdded()}
								value={values.selectedRole}
								onChange={selectedOption => setFieldValue('selectedRole', selectedOption)}
								classNamePrefix='react-select'
								placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
								name='selectedRole'
							/>
							{errors.selectedRole && <span className='red-error'>{errors.selectedRole}</span>}
							{showHealthSystem(values.selectedRole) && (
								<>
									<p>{translate('selectAtLeastOneHSToAddRole', { value: translate('memberRole') })}</p>
									<Select
										options={healthSystemsArr}
										value={values.healthSystem}
										onChange={selectedOption => setFieldValue('healthSystem', selectedOption)}
										classNamePrefix='react-select'
										placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
										name='healthSystem'
									/>
									{errors.healthSystem && <span className='red-error'>{errors.healthSystem}</span>}
								</>
							)}
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AddMemberRole;
